/**
* Title: PROTOTYP WEBSITE
* Author/s: PROTOTYP
* CSS build platform: Webpack
* CSS development build command: npm start
* CSS production build command: npm run build
* CSS processor: PostCSS
* CSS processor usage: autoprefixing, nesting, variables, minification, pxtorem, color functions
* CSS name convention: BEM
* Global indentation: 4 spaces
* Global units: px
* Global prefix: none
* Browser support: IE10+, last 3 versions of modern browsers
* Additional notes:
*/

/**
* Import: vars
* Description: variables
*/

/*------------------------------------*\
    # vars.globals
\*------------------------------------*/

/**
 * The $unit variable is a global variable to be used
 * in paddings, margins, for sizing and positioning
 */

/**
 * The $spacer variable is a global variable to be used
 * in to create a unified spacer for all kinds of elements
 */

/**
 * Global class prefix - usage: .$(global-prefix)classname
 */

/**
 * Global radius and rounded
 */

/**
 * Grid
 */

/*------------------------------------*\
    # vars.typography
\*------------------------------------*/

/**
 * Project base line height (unitless in order to work with typomatic)
 */

/**
 * Type scale sizes must be entered in their pixel size
 * (unitless in order to work with typomatic)
 * Default type scale ratio: 1.333;
 */

/** 
 * Type weights
 */

/**
 * Font stacks
 */

/*------------------------------------*\
    # vars.responsive
\*------------------------------------*/

/* 544px */

/* 543px */

/* 544px */

/* 543px */

/* 768px */

/* 767px */

/* 992px */

/* 991px */

/* 1200px */

/* 1199px */

/* 1440px */

/* 1439px */

/* 1660px */

/* 1660px */

/* 2100px */

/* 2199px */

/*------------------------------------*\
    # vars.colors
\*------------------------------------*/

/**
 * Brand colors
 */

/**
 * Global text color
 */

/**
 * Theme colors
 */

/**
 * Utility colors
 */

/**
* Import: tools
* Description: tools like typomatic and other mixins / libraries
*/

/*------------------------------------*\
    # tools.typomatic
\*------------------------------------*/

/**
 * Mixin name: typomatic-init
 * Description: creates a vertical rhythm on a page using font-size
 * and line-height on the html element
 * Parameters: 
 * does not take parameters
 */

/**
 * Mixin name: type-scale
 * Description: type-scale sets the type to baseline to achieve
 * vertical rhythm.
 * Parameters: 
 * $scale ($base-font-size is default) - font size (unitless) variable
 * $baselines (1 is default) - number of baselines
 */

/*------------------------------------*\
    # tools.clearfix
\*------------------------------------*/

/**
 * Mixin name: clearfix
 * Description: applies clearfix to element
 * Parameters: 
 * does not take parameters
 */

/**
* Import: generic
* Description: normalize or reset CSS, box sizing
*/

/*------------------------------------*\
    # generic.boxsizing
\*------------------------------------*/

*,
*:before,
*:after {
    -webkit-box-sizing: inherit;
            box-sizing: inherit;
}

html {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

/**
* Import: base
* Description: base structural stylings for setting the body, typography
* and other base styles (no classes should be added here)
*/

/*------------------------------------*\
    # base.globals
\*------------------------------------*/

/**
 * Initialize typomatic in project (sets font-size and line-height
 * on html selector.
 */

html {
    font-size: 100%;
    line-height: 1.5;
    scroll-behavior: smooth;
}

@-webkit-keyframes colorChangeWB {
    from {
        background: white;
    }

    to {
        background: #1F1F1F;
    }
}

@keyframes colorChangeWB {
    from {
        background: white;
    }

    to {
        background: #1F1F1F;
    }
}

@-webkit-keyframes colorChangeBW {
    from {
        background: #1F1F1F;
    }

    to {
        background: white;
    }
}

@keyframes colorChangeBW {
    from {
        background: #1F1F1F;
    }

    to {
        background: white;
    }
}

body {
    position: relative;
    font-family: 'Aeonik', Helvetica, Arial, sans-serif;
    font-weight: 400;
    color: #242424;
    background: #1E1ED2;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    -webkit-tap-highlight-color: transparent;
}

body.is-light {
        -webkit-animation-name: colorChangeBW;
                animation-name: colorChangeBW;
        -webkit-animation-duration: 0.4s;
                animation-duration: 0.4s;
        -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
    }

body.is-dark {
        -webkit-animation-name: colorChangeWB;
                animation-name: colorChangeWB;
        -webkit-animation-duration: 0.4s;
                animation-duration: 0.4s;
        -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
    }

/* Lock scrolling on mobile nav open */

body.is-locked {
        overflow: hidden;
        position: fixed;
        width: 100%;
    }

/* WebKit/Blink Browsers */

body ::-moz-selection {
        background: #1F1F1F;
        color: white;
    }

body ::selection {
        background: #1F1F1F;
        color: white;
    }

/* Gecko Browsers */

body ::-moz-selection {
        background: #1F1F1F;
        color: white;
    }

img {
    max-width: 100%;
    vertical-align: middle;
}

svg {
    max-width: 100%;
}

picture {
    display: block;
}

/*------------------------------------*\
    # base.typography
\*------------------------------------*/

/**
 * Headings
 */

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: 400;
}

h1 {
    font-size: 31px;
    line-height: 40px;
}

h2 {
    font-size: 25px;
    line-height: 36px;
}

h3 {
    font-size: 20px;
    line-height: 28px;
}

h4,
h5,
h6 {
    font-size: 16px;
    line-height: 24px;
}

/**
 * Paragraphs
 */

p {
    margin: 0;
}

/**
 * Lists (ol, ul, dd)
 */

ol,
ul,
dl {
    margin: 0;
    padding: 0;
    list-style: none;
}

ol {}

ol li {}

ul {}

ul li {}

dd {}

dl dt {}

dl dd {}

/**
 * Anchors
 */

a {
    color: #1E1ED2;
    text-decoration: none;
}

a:link {}

a:hover {
        color: rgb(31, 31, 145);
    }

a:focus {
        color: rgb(31, 31, 145);
    }

a:visited {}

/**
 * Typographic details
 */

hr {
    padding: 8px 0;
    border: 0;
    border-bottom: 1px solid rgb(222, 222, 222);
}

em {}

b,
strong {
    font-weight: 700;
}

address {
    font-style: normal;
}

small {}

pre {}

code {}

sub {}

sup {}

strike {}

/**
 * Tables
 */

table th {
        text-align: left;
    }

/**
 * Blockquote
 */

blockquote {
    margin: 0;
}

cite {
    font-style: normal;
}

/*------------------------------------*\
    # base.reveal-animation
\*------------------------------------*/

.reveal-animation {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    height: 0;
    width: 100%;

    background: #1E1ED2;
}

/**
* Import: component
* Description: cross project reusable 1
*/

/*------------------------------------*\
    # component.container
\*------------------------------------*/

.container {
    overflow: hidden;
}

/*------------------------------------*\
    # component.wrapper
\*------------------------------------*/

.wrapper {
    width: 100%;
    max-width: 1704px;
    padding: 0 32px;
    margin: 0 auto;
}

@media    (min-width: 34em) {

.wrapper {
        max-width: 1720px;
        padding: 0 40px
}
    }

@media   (min-width: 48em) {

.wrapper {
        max-width: 1768px;
        padding: 0 64px
}
    }

@media   (min-width: 75em) {

.wrapper {
        max-width: 1800px;
        padding: 0 80px
}
    }

/*------------------------------------*\
    # component.logo
\*------------------------------------*/

@media    (min-width: 62em) {

.logo {

        position: relative
}

        .logo:before {
            content: '';
            display: block;
            position: absolute;
            left: -60px;
            top: 6px;
            width: 0px;
            height: 2px;
            background: #1E1ED2;
            -webkit-transition: all 0.2s cubic-bezier(0.76, 0, 0.24, 1);
            transition: all 0.2s cubic-bezier(0.76, 0, 0.24, 1);
        }
            .logo:hover:before, .logo:focus:before {
                width: 40px;
            }
    }

.logo svg {
    fill: #1E1ED2;
}

@media      (max-width: 33.9375em) {

.logo svg {
        width: 16px
}
    }

.logo--alt:before {
        background: white;
    }

.logo--alt svg {
        fill: white;
    }

.logo--fixed svg {
        fill: white;
    }

/*------------------------------------*\
    # component.btn
\*------------------------------------*/

.btn {
    /**
     * Button base
     */
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;

    -ms-touch-action: manipulation;

        touch-action: manipulation;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
    text-align: center;
    border: 0;
    background: transparent;

    -webkit-transition: all 0.15s ease-in-out;

    transition: all 0.15s ease-in-out;

    /**
     * Button base project styling
     */
    font-family: 'Aeonik', Helvetica, Arial, sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.2em;
}

.btn:focus {
        outline: 0;
    }

/**
     * Disabled
     */

.btn.is-disabled,
    .btn:disabled {
        cursor: not-allowed;
        opacity: 0.6;
        background-color: transparent;
    }

.btn.is-disabled {
        pointer-events: none;
    }

/**
 * Button styles
*/

.btn--primary {
    position: relative;
    z-index: 1;
    overflow: hidden;

    color: rgba(36, 36, 36, 0.9);
    border-radius: 1000px;
    font-weight: 400;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;

    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);

            box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
}

.btn--primary:before,
    .btn--primary:after {
        content: "";
        display: block;
        height: 100%;

        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;

        -webkit-transition: all 0.25s;

        transition: all 0.25s;
    }

.btn--primary:before {
        width: 100%;
        background: white;
    }

.btn--primary:after {
        width: 0;
        background: #1E1ED2;
    }

.btn--primary:hover,
    .btn--primary:focus {
        color: white;
    }

.btn--primary:hover:after, .btn--primary:focus:after {
            width: 100%;
        }

.btn--dark {
    position: relative;
    z-index: 1;
    overflow: hidden;

    color: white;
    border-radius: 1000px;
    font-weight: 400;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;

    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);

            box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
}

.btn--dark:before,
    .btn--dark:after {
        content: "";
        display: block;
        height: 100%;

        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;

        -webkit-transition: all 0.25s;

        transition: all 0.25s;
    }

.btn--dark:before {
        width: 100%;
        background: #1F1F1F;
    }

.btn--dark:after {
        width: 0;
        background: #1E1ED2;
    }

.btn--dark:hover,
    .btn--dark:focus {
        color: white;
    }

.btn--dark:hover:after, .btn--dark:focus:after {
            width: 100%;
        }

.btn--secondary {
    background-color: transparent;
    color: rgba(36, 36, 36, 0.9);
    border: 1px solid rgba(36, 36, 36, 0.2);
    border-radius: 1000px;
    font-weight: 400;
}

.btn--secondary:hover,
    .btn--secondary:focus {
        color: #242424;
        border-color: rgba(36, 36, 36, 0.4);
    }

.btn--secondary:active {
        -webkit-transition: all 0.15s;
        transition: all 0.15s;
        -webkit-box-shadow: inset 1px 1px 3px rgba(0, 0, 0, .1);
                box-shadow: inset 1px 1px 3px rgba(0, 0, 0, .1);
    }

.btn--tertiary {
    background-color: transparent;
    color: rgba(36, 36, 36, 0.7);
    font-weight: 400;
    letter-spacing: 0.1em;
}

.btn--tertiary:hover,
    .btn--tertiary:focus {
        color: #242424;
        text-decoration: underline;
    }

.btn--tertiary:active {
        -webkit-transition: all 0.15s;
        transition: all 0.15s;
    }

.btn--round {
    border-radius: 1000px;
    background: #1E1ED2;
    -webkit-box-shadow: 0px 0px 10px 2px rgba(11, 11, 221, 0.4);
            box-shadow: 0px 0px 10px 2px rgba(11, 11, 221, 0.4);
}

/**
 * Button sizes
 */

.btn--sml {
    font-size: 11px;
    line-height: 16px;
    height: 16px;
    padding: 0 8px;
}

.btn--med {
    font-size: 11px;
    line-height: 16px;
    height: 32px;
    padding: 0 32px;
}

.btn--lrg {
    font-size: 14px;
    line-height: 16px;
    height: 48px;
    padding: 0 48px;
}

@media     (max-width: 47.9375em) {

.btn--xlrg {
        font-size: 14px;
        line-height: 16px;
        height: 48px;
        padding: 0 32px
}
    }

@media   (min-width: 48em) {

.btn--xlrg {
        font-size: 14px;
        line-height: 16px;
        height: 60px;
        padding: 0 60px
}
    }

/**
 * Button arrow
 */

.btn__arrow {
    position: relative;
    width: 32px;
    margin-left: 16px;
}

.btn__arrow:before,
    .btn__arrow:after {
        content: '';
        display: block;
        position: absolute;
    }

.btn__arrow:before {
        width: 24px;
        height: 1px;
        background: #1F1F1F;
    }

.btn__arrow:after {
        width: 5px;
        height: 5px;
        border-right: 1px solid #1F1F1F;
        border-bottom: 1px solid #1F1F1F;
        right: 7px;
        top: -2px;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
    }

/*------------------------------------*\
    # component.actionbutton
\*------------------------------------*/

.actionbutton {
    display: block;
    position: relative;
    width: 60px;
    height: 60px;
    border: 1px solid transparent;
    border-radius: 50%;
    overflow: hidden;

    -webkit-transition: all .35s cubic-bezier(0.76, 0, 0.24, 1);

    transition: all .35s cubic-bezier(0.76, 0, 0.24, 1);
}

@media   (min-width: 48em) {

.actionbutton {
        width: 80px;
        height: 80px
}
    }

.actionbutton svg {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);

        -webkit-transition: all .35s cubic-bezier(0.76, 0, 0.24, 1);

        transition: all .35s cubic-bezier(0.76, 0, 0.24, 1);
    }

.actionbutton svg:nth-child(1) {
            -webkit-transform: translate(-72px, -50%);
                    transform: translate(-72px, -50%);
        }

.actionbutton:hover svg:nth-child(1) {
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
        }

.actionbutton:hover svg:nth-child(2) {
            -webkit-transform: translate(72px, -50%);
                    transform: translate(72px, -50%);
        }

.actionbutton--primary {
    background: #1E1ED2;
}

.actionbutton--primary path {
        stroke: white;
    }

.actionbutton--primary:hover,
    .actionbutton--primary:focus {
        border: 1px solid #1E1ED2;
        background: transparent;
    }

.actionbutton--primary:hover path, .actionbutton--primary:focus path {
            stroke: #1E1ED2;
        }

.actionbutton--light {
    background: white;
}

.actionbutton--light path {
        stroke: #1F1F1F;
    }

.actionbutton--light:hover,
    .actionbutton--light:focus {
        border: 1px solid white;
        background: transparent;
    }

.actionbutton--light:hover path, .actionbutton--light:focus path {
            stroke: white;
        }

/*------------------------------------*\
    # component.actioncircle
\*------------------------------------*/

.actioncircle {
    position: relative;

    display: -webkit-inline-box;

    display: -ms-inline-flexbox;

    display: inline-flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    width: 120px;
    height: 120px;
    font-size: 11px;
    line-height: 16px;

    -webkit-transition: all 0.35s cubic-bezier(0, 0.55, 0.45, 1);

    transition: all 0.35s cubic-bezier(0, 0.55, 0.45, 1);
}

@media   (min-width: 48em) {

.actioncircle {
        font-size: 14px;
        line-height: 16px;
        width: 160px;
        height: 160px
}
    }

.actioncircle:before {
        content: '';
        display: block;

        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        border: 1px solid transparent;
        border-radius: 50%;

        -webkit-transition: all 0.25s cubic-bezier(0, 0.55, 0.45, 1);

        transition: all 0.25s cubic-bezier(0, 0.55, 0.45, 1);
    }

.actioncircle--primary:before {
        border-color: #1E1ED2;
    }

.actioncircle--primary:hover,
    .actioncircle--primary:focus {

        -webkit-transform: scale(1.15);

                transform: scale(1.15);
    }

.actioncircle--primary:hover:before, .actioncircle--primary:focus:before {
            border-color: rgb(31, 31, 145);
        }

.actioncircle--negative {
    color: white;
}

.actioncircle--negative:before {
        border-color: white;
    }

.actioncircle--negative:hover,
    .actioncircle--negative:focus {
        color: white;
        -webkit-transform: scale(1.15);
                transform: scale(1.15);
    }

/*------------------------------------*\
    # component.anchor
\*------------------------------------*/

.anchor {
    position: relative;
    text-decoration: none;

    background-image: -webkit-gradient(linear, left top, left bottom, from(currentColor), to(currentColor));

    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    -webkit-transition: background-size 0.3s cubic-bezier(0.76, 0, 0.24, 1);
    transition: background-size 0.3s cubic-bezier(0.76, 0, 0.24, 1);
}

.anchor:hover,
    .anchor:focus {
        background-size: 0 0.05em;
    }

.anchor--text {
    color: #242424;
}

.anchor--text:hover,
    .anchor--text:focus {
        color: #242424;
    }

.anchor--negative {
    color: white;
}

.anchor--negative:hover,
    .anchor--negative:focus {
        color: white;
    }

/*------------------------------------*\
    # component.input
\*------------------------------------*/

.input {
    /**
     * Input base
     */
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 0;
    display: inline-block;
    vertical-align: middle;
    width: 100%;

    -webkit-border-radius: 0; /* Prefixed to remove border radius on iOS */

    cursor: pointer;
    white-space: nowrap;

    text-decoration: none;

    font-size: 20px;

    line-height: 32px;
    font-family: 'MaisonNeueExtended', Helvetica, Arial, sans-serif;
    font-weight: 300;

    background: transparent;
    border-bottom: 1px solid rgba(36, 36, 36, 0.3);
}

.input:focus {
        border-color: #1E1ED2;
        outline: 0;
    }

.input::-webkit-input-placeholder {
        padding: 0 8px;
    }

.input::-moz-placeholder {
        padding: 0 8px;
    }

.input::-ms-input-placeholder {
        padding: 0 8px;
    }

.input::placeholder {
        padding: 0 8px;
    }

/**
 * input sizes
 */

.input--sml {
    font-size: 14px;
    line-height: 24px;
    height: 16px;
    line-height: 16px;
}

.input--med {
    height: 24px;
    line-height: 24px;
}

.input--lrg {
    height: 48px;
    line-height: 48px;
}

/**
 * input types
 */

.input--text {
}

.input--number {
    padding-right: 0;
}

.input--text {
}

.input--select {
}

.input--select--multiple {
    min-height: 160px;
}

.input--textarea {
    min-height: 160px;
    resize: vertical;
    border: 1px solid rgb(196, 196, 196);
    padding: 0 8px;
}

.input--check,
.input--radio {
    position: absolute;
    left: -99999px;
    opacity: 0;
}

/* input[type="hidden"] is added since MVC.NET
     * generates a hidden field after the input element
     */

.input--check + input[type="hidden"] + label,
    .input--check + input[type="hidden"] + input[type="hidden"] + label,
    .input--radio + input[type="hidden"] + label,
    .input--radio + input[type="hidden"] + input[type="hidden"] + label {
        padding-left: 8px;
    }

.input--check + input[type="hidden"] + label:before, .input--check + input[type="hidden"] + input[type="hidden"] + label:before, .input--radio + input[type="hidden"] + label:before, .input--radio + input[type="hidden"] + input[type="hidden"] + label:before {
            content: "";
            display: block;
            float: left;
            width: 10px;
            height: 10px;
            margin-top: 2px;

            -webkit-transition: all 0.2s ease-in-out;

            transition: all 0.2s ease-in-out;

            border: 1px solid rgb(196, 196, 196);
        }

.input--check:focus + input[type="hidden"] + label:before, .input--check:focus + input[type="hidden"] + input[type="hidden"] + label:before, .input--radio:focus + input[type="hidden"] + label:before, .input--radio:focus + input[type="hidden"] + input[type="hidden"] + label:before {
                -webkit-box-shadow: 0 0 5px #1E1ED2;
                        box-shadow: 0 0 5px #1E1ED2;
            }

.input--check:checked + input[type="hidden"] + label:before, .input--check:checked + input[type="hidden"] + input[type="hidden"] + label:before, .input--radio:checked + input[type="hidden"] + label:before, .input--radio:checked + input[type="hidden"] + input[type="hidden"] + label:before {
                background: #1E1ED2;
                border-color: transparent;
            }

.input--radio + input[type="hidden"] + label:before, .input--radio + input[type="hidden"] + input[type="hidden"] + label:before {
            border-radius: 50%;
        }

/*------------------------------------*\
    # component.field
\*------------------------------------*/

.field {
    /**
     * Field base
     */
    padding-bottom: 24px;
    position: relative;
    text-align: left;
}

.field__lbl {
    display: block;
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 28px;
    color: rgba(36, 36, 36, 0.7);
}

.field__lbl__number {
    margin-right: 20px;
    margin-left: 0;
    font-size: 20px;
    line-height: 28px;
    color: rgba(36, 36, 36, 0.3);
}

@media   (min-width: 48em) {

.field__lbl__number {
        margin-left: -32px
}
    }

.field__lbl--error {
    color: #F44336;
    opacity: 0;
}

/*------------------------------------*\
    # component.alert
\*------------------------------------*/

.alert {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 14px;
    line-height: 20px;
}

.alert--success {
    color: #4CAF50;
}

.alert--warning {
    color: #F44336;
}

.alert--note {
    color: #E2BA45;
}

/*------------------------------------*\
    # component.layout
\*------------------------------------*/

.layout {
    
}

/* @import "./modules.hamburger.css"; */

/*------------------------------------*\
    # component.formatted
\*------------------------------------*/

.formatted p,
    .formatted table,
    .formatted object,
    .formatted img {
        margin-bottom: 24px;
    }

.formatted ul,
    .formatted ol,
    .formatted dl,
    .formatted blockquote {
        margin-bottom: 48px;
    }

.formatted h1,
    .formatted h2,
    .formatted h3,
    .formatted h4,
    .formatted h5,
    .formatted h6 {
        margin: 32px 0;
    }

.formatted strong {}

.formatted p:last-child {
            margin-bottom: 0 !important;
        }

.formatted blockquote {
        margin-left: 0;

        padding-left: 32px;
        border-left: 1px solid rgba(31, 31, 31, 0.1);
    }

.formatted blockquote p {}

/**
* Import: blocks
* Description: specific website/app components
*/

/*------------------------------------*\
    # block.nav
\*------------------------------------*/

@media      (max-width: 61.9375em) {

.nav {
        position: fixed;
        z-index: 5;
        bottom: 0;
        left: 0;
        width: 100%;
        padding-bottom: 32px;
        padding-left: 8px;

        visibility: hidden
}

        .nav.is-visible {
            visibility: visible;
            overflow: hidden;
        }

            .nav.is-visible .nav__item {
                -webkit-transform: translateY(0);
                        transform: translateY(0);
                opacity: 1;
            }

                .nav.is-visible .nav__item:nth-child(2) {
                    -webkit-transition-delay: 0.03s;
                            transition-delay: 0.03s;
                }

                .nav.is-visible .nav__item:nth-child(3) {
                    -webkit-transition-delay: 0.06s;
                            transition-delay: 0.06s;
                }

                .nav.is-visible .nav__item:nth-child(4) {
                    -webkit-transition-delay: 0.09s;
                            transition-delay: 0.09s;
                }

                .nav.is-visible .nav__item:nth-child(5) {
                    -webkit-transition-delay: 0.12s;
                            transition-delay: 0.12s;
                }
    }

@media   (min-width: 48em) and      (max-width: 61.9375em) {

.nav {
        padding-bottom: 12px
}
    }

@media (max-height: 450px) {

.nav {
        text-align: right;
        padding-bottom: 12px;
        padding-right: 32px
}
    }

@media    (min-width: 62em) {

.nav__wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between
}
    }

.nav__open {
    position: fixed;
    z-index: 999;
    height: 48px;
    width: 48px;

    background: #1F1F1F;
    filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur in="SourceAlpha" stdDeviation="10" /><feOffset dx="5" dy="5" result="offsetblur" /><feFlood flood-color="rgba(0,0,0,0.3)" /><feComposite in2="offsetblur" operator="in" /><feMerge><feMergeNode /><feMergeNode in="SourceGraphic" /></feMerge></filter></svg>#filter');
    -webkit-filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.3));
            filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.3));
    border-radius: 50%;

    top: 24px;
    right: 24px;
}

@media    (min-width: 62em) {

.nav__open {
        display: none
}
    }

.nav__open:before,
    .nav__open:after {
        content: '';
        position: absolute;
        background: white;
        -webkit-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;
    }

.nav__open:before {
        top: 26px;
        left: 13px;
        height: 2px;
        width: 23px;
        border-radius: 1px;
    }

.nav__open:after {
        top: 20px;
        left: 13px;
        height: 2px;
        width: 23px;
        border-radius: 1px;
    }

.nav__open.is-active:after {
            top: 23px;
            width: 14px;
            left: 18px;
            -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
        }

.nav__open.is-active:before {
            -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg);
            width: 14px;
            left: 18px;
            top: 23px;
        }

.nav__item {
    position: relative;
    z-index: 10;
    display: block;
}

/* Animate the items when the mobile nav is opened */

@media      (max-width: 61.9375em) {

.nav__item {
        -webkit-transform: translateY(60px);
                transform: translateY(60px);
        opacity: 0;
        -webkit-transition: all 0.6s cubic-bezier(0.76, 0, 0.24, 1);
        transition: all 0.6s cubic-bezier(0.76, 0, 0.24, 1)
}
    }

@media    (min-width: 62em) {

.nav__item--home {
        display: none
}
    }

.nav__link {
    display: block;
    -webkit-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
    font-family: 'MaisonNeueExtended', Helvetica, Arial, sans-serif;
}

@media      (max-width: 61.9375em) {

.nav__link {
        padding: 8px 20px;

        font-size: 25px;

        line-height: 40px;
        color: #717171
}

        .nav__link span {
            display: block;
            -webkit-transition: all 0.25s ease-out;
            transition: all 0.25s ease-out;
            color: white;
        }
            .nav__link.active span {
                color: rgba(255, 255, 255, 0.4);
            }
    }

@media    (min-width: 62em) {

.nav__link {
        padding: 28px 24px;
        font-size: 14px;
        line-height: 16px
}

        .nav__link span {
            position: relative;
            display: block;
            -webkit-transition: all 0.25s ease-out;
            transition: all 0.25s ease-out;
            color: #717171;
        }

            .nav__link span:after {
                content: '';
                display: block;
                position: absolute;
                bottom: -2px;
                left: 0;
                width: 100%;
                height: 1px;
                background: transparent;
            }

        .nav__link:before {
            content: attr(data-hover);

            position: absolute;
            z-index: 1;
            -webkit-transform: translateY(-12px);
                    transform: translateY(-12px);

            color: white;
            opacity: 0;
            -webkit-transition: all 0.25s ease-out;
            transition: all 0.25s ease-out;
        }

        .nav__link.active {
            color: #242424;
        }

            .nav__link.active span {
                color: #242424;
            }

                .nav__link.active span:after {
                    background: #717171;
                }

                .is-alternate .nav__link.active span {
                    color: white;
                }

                    .is-alternate .nav__link.active span:after {
                        background: white;
                    }

            .nav__link.active:before {
                text-decoration: underline;
                -webkit-text-decoration-color: #717171;
                        text-decoration-color: #717171;
                text-underline-offset: 4px;
            }

                .is-alternate .nav__link.active:before {
                    color: white;
                    -webkit-text-decoration-color: white;
                            text-decoration-color: white;
                }
            .is-alternate .nav__link span {
                color: #D9D9D9;
            }
            .nav__link:hover:before, .nav__link:focus:before {
                color: #242424;
                opacity: 1;
                -webkit-transform: translateY(0);
                        transform: translateY(0);
            }

                .is-alternate .nav__link:hover:before, .is-alternate .nav__link:focus:before {
                    color: white;
                }

            .nav__link:hover span, .nav__link:focus span {
                color: #242424;
                opacity: 0;
                -webkit-transform: translateY(12px);
                        transform: translateY(12px);
            }

                .is-alternate .nav__link:hover span, .is-alternate .nav__link:focus span {
                    color: white;
                }
    }

@media      (max-width: 61.9375em) and (max-height: 450px) {

.nav__link {
        padding: 12px 24px
}
    }

@media    (min-width: 62em) {

.nav__link--contact {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            margin-left: 48px;
            font-weight: 700
    }

            .nav__link--contact span {
                color: #1E1ED2;
            }

            .nav__link--contact:after {
                content: '';
                position: absolute;
                left: 24px;
                width: 1px;
                height: 24px;
                background: #D9D9D9;
                pointer-events: none;
            }

                .is-alternate .nav__link--contact:after {
                    background: #4E4E4E;
                }
                .nav__link--contact:hover:before, .nav__link--contact:focus:before {
                    color: #1E1ED2;
                }

                .nav__link--contact:hover span, .nav__link--contact:focus span {
                    color: #1E1ED2;
                }

                    .nav__link--contact:hover span:after, .nav__link--contact:focus span:after {
                        background: transparent;
                    }
                .nav__link--contact.active span {
                    color: #1E1ED2;
                }

                    .nav__link--contact.active span:after {
                        background: #1E1ED2;
                    }

                    .is-alternate .nav__link--contact.active span {
                        color: #1E1ED2;
                    }

                .nav__link--contact.active:before {
                    -webkit-text-decoration-color: #1E1ED2;
                            text-decoration-color: #1E1ED2;
                }
        }

/*------------------------------------*\
    # block.cookie
\*------------------------------------*/

.cookie {
    position: fixed;
    z-index: 100;
    bottom: 0;

    padding: 24px 24px 8px 24px;

    border-radius: 4px 4px 0 0;
    background: white;
    -webkit-box-shadow: 0 0 20px rgba(31, 31, 31, 0.1);
            box-shadow: 0 0 20px rgba(31, 31, 31, 0.1);

}

@media      (max-width: 61.9375em) {

.cookie {
        right: 30px;
        left: 30px

}
    }

@media    (min-width: 62em) {

.cookie {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        left: 50%;

        width: 100%;
        max-width: 1400px;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        
        padding: 24px

}
    }

@media      (max-width: 61.9375em) {

.cookie__content {
        margin-bottom: 24px
}
    }

@media    (min-width: 62em) {

.cookie__content {
        -webkit-box-flex: 6;
            -ms-flex: 6;
                flex: 6;
        padding-right: 16px
}
    }

@media      (max-width: 61.9375em) {

.cookie .btn {
        width: 100%;
        text-align: center;
        margin-bottom: 16px
}
    }

@media    (min-width: 62em) {

.cookie .btn {
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1;
        margin-right: 16px
}
    }

/*------------------------------------*\
    # block.header
\*------------------------------------*/

.header {
    z-index: 6;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

@media      (max-width: 61.9375em) {

.header {
        margin-top: 32px
}

        .header:before {
            visibility: hidden;
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            content: '';
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(31, 31, 31, 0.8);

            opacity: 0;
            -webkit-backdrop-filter: blur(0);
                    backdrop-filter: blur(0);
        }
    }

@media    (min-width: 62em) {

.header {
        overflow: hidden
}
    }

@media      (max-width: 61.9375em) {

.header.is-visible {
        height: 100%;
        padding-bottom: 108px
}
            @media      (max-width: 61.9375em) {

        .header.is-visible:before {
                opacity: 1;
                visibility: visible;
                -webkit-backdrop-filter: blur(5px);
                        backdrop-filter: blur(5px)
        }
            }
    }

@media      (max-width: 61.9375em) {

.header__wrapper {
        padding: 0 32px
}
    }

@media    (min-width: 62em) {

.header__wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        padding: 0 36px 0 60px
}
    }

.header__branding {
    padding-top: 8px;
}

@media   (min-width: 48em) {

.header__branding {
        padding-top: 16px
}
    }

@media    (min-width: 62em) {

.header__branding {
        padding-top: 44px
}
    }

@media    (min-width: 62em) {

.header__nav {
        padding-top: 20px
}
    }

/*------------------------------------*\
    # block.footer
\*------------------------------------*/

.footer {
    position: relative;
    z-index: 0;
    padding: 80px 0;
    background: rgb(31, 31, 31);
}

.footer__top {

    margin-bottom: 10vh;
}

@media   (min-width: 48em) {

.footer__top {
        display: flex;
        flex-flow: row wrap;
}
    }

@media     (max-width: 47.9375em) {

.footer__logo {
        margin-bottom: 5vh

}
    }

@media   (min-width: 48em) {

.footer__logo {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 4/12 - (40px - 40px * 4/12));
        max-width: calc(99.9% * 4/12 - (40px - 40px * 4/12));
        width: calc(99.9% * 4/12 - (40px - 40px * 4/12))

}

.footer__logo:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0

}

.footer__logo:last-child {
        margin-right: 0

}

.footer__logo:nth-child(12n) {
        margin-right: 0;
        margin-left: auto

}
    }

.footer__logo svg {
        fill: rgba(255, 255, 255, 0.2);
    }

@media   (min-width: 48em) {

.footer__pangea {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 3/12 - (40px - 40px * 3/12));
        max-width: calc(99.9% * 3/12 - (40px - 40px * 3/12));
        width: calc(99.9% * 3/12 - (40px - 40px * 3/12));
        margin-left: calc(99.9% * (-5/12 * -1) - (40px - 40px * (-5/12 * -1)) + 40px) !important
}

.footer__pangea:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.footer__pangea:last-child {
        margin-right: 0
}

.footer__pangea:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.footer__pangea a {
        display: block;
        -webkit-transition: all .2s ease-out;
        transition: all .2s ease-out;
    }

.footer__pangea a:hover,
        .footer__pangea a:focus {
            opacity: 0.5;
        }

.footer__pangea p {
        padding-right: 32px;
    }

.footer__pangea svg {
        width: 120px;
    }

.footer__links {
    display: flex;
    flex-flow: column nowrap;
}

@media   (min-width: 48em) {

.footer__links {
        display: flex;
        flex-flow: row wrap
}
    }

.footer__links__group {
    margin-bottom: 40px;
}

.footer__links__group:last-child {
        margin-bottom: 0;
    }

@media   (min-width: 48em) {

.footer__links__group--pages {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 2/12 - (40px - 40px * 2/12));
        max-width: calc(99.9% * 2/12 - (40px - 40px * 2/12));
        width: calc(99.9% * 2/12 - (40px - 40px * 2/12))
}

.footer__links__group--pages:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.footer__links__group--pages:last-child {
        margin-right: 0
}

.footer__links__group--pages:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media   (min-width: 48em) {

.footer__links__group--publishing {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/12 - (40px - 40px * 1/12));
        max-width: calc(99.9% * 1/12 - (40px - 40px * 1/12));
        width: calc(99.9% * 1/12 - (40px - 40px * 1/12));
        margin-left: calc(99.9% * (-2/12 * -1) - (40px - 40px * (-2/12 * -1)) + 40px) !important
}

.footer__links__group--publishing:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.footer__links__group--publishing:last-child {
        margin-right: 0
}

.footer__links__group--publishing:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media   (min-width: 48em) {

.footer__links__group--social {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/12 - (40px - 40px * 1/12));
        max-width: calc(99.9% * 1/12 - (40px - 40px * 1/12));
        width: calc(99.9% * 1/12 - (40px - 40px * 1/12))
}

.footer__links__group--social:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.footer__links__group--social:last-child {
        margin-right: 0
}

.footer__links__group--social:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media   (min-width: 48em) {

.footer__links__group--company {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 3/12 - (40px - 40px * 3/12));
        max-width: calc(99.9% * 3/12 - (40px - 40px * 3/12));
        width: calc(99.9% * 3/12 - (40px - 40px * 3/12));
        margin-left: calc(99.9% * (-1/12 * -1) - (40px - 40px * (-1/12 * -1)) + 40px) !important
}

.footer__links__group--company:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.footer__links__group--company:last-child {
        margin-right: 0
}

.footer__links__group--company:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.footer__links__link {
    position: relative;
    display: inline-block;

    color: white;
    -webkit-transition: all 0.15s;
    transition: all 0.15s;
    font-size: 14px;
    line-height: 24px;
}

.footer__links__link:after {
        content: '';
        display: block;
        width: 0;
        height: 1px;
        background: white;
        -webkit-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;
    }

.footer__links__link:hover,
    .footer__links__link:focus {
        color: white;
    }

.footer__links__link:hover:after, .footer__links__link:focus:after {
            width: 100%;
        }

.footer__text {
    font-size: 14px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.5);
}

/*------------------------------------*\
    # block.hero
\*------------------------------------*/

@-webkit-keyframes strokeAnimation {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes strokeAnimation {
    to {
        stroke-dashoffset: 0;
    }
}

.hero {
    position: relative;
}

.hero__wrapper {
    min-height: 100vh;
}

@media      (max-width: 61.9375em) {

.hero__wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center
}
    }

@media    (min-width: 62em) {

.hero__wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between
}
    }

.hero__claim {
    color: white;
}

@media    (min-width: 62em) {

.hero__claim {
        margin-left: 10%;
        max-width: 560px
}
    }

.hero__title {
    display: block;
    margin-bottom: 10vh;
    letter-spacing: -0.01em;
}

@media      (max-width: 61.9375em) {

.hero__title {
        text-align: center;
        padding: 0 32px
}
    }

.hero__more {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    width: 160px;
    height: 160px;
    color: rgba(255, 255, 255, 0.7);

    -webkit-transition: all .5s ease-out;

    transition: all .5s ease-out;
}

.hero__more:after {
        content: '';
        display: block;

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 50%;

        -webkit-transition: all .5s ease-out;

        transition: all .5s ease-out;
    }

.hero__more:hover,
    .hero__more:focus {
        color: white;
    }

.hero__more:hover:after, .hero__more:focus:after {
            border: 1px solid rgba(255, 255, 255, 0);
        }

.hero__more:hover .hero__more__circle circle, .hero__more:focus .hero__more__circle circle {
            stroke-dasharray: 1000;
            stroke-dashoffset: 1000;
            -webkit-animation: strokeAnimation 1s cubic-bezier(0.76, 0, 0.24, 1) forwards;
                    animation: strokeAnimation 1s cubic-bezier(0.76, 0, 0.24, 1) forwards;
        }

@media      (max-width: 61.9375em) {

.hero__more {
        margin: 0 auto
}
    }

.hero__more__circle {
    position: absolute;
    top: 1px;
    left: 0;
}

.hero__more__circle circle {
    stroke-dasharray: 500;
    stroke-dashoffset: 500;
    stroke-width: 1px;
    stroke: rgba(255, 255, 255, 0.7);
    fill-opacity: 0;
}

.hero__visual {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 50%;
    background-image: url(../assets/images/visuals/cover.png);
    background-position: 5vh 15vh;
    background-repeat: no-repeat;
}

@media     (max-width: 47.9375em) {

.hero__visual {
        opacity: 0.4 !important
}
    }

.hero__visualoverlay {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -2;
    height: 100%;
    width: 50%;
    background: #292D41;
}

/*------------------------------------*\
    # block.case-study-widget
\*------------------------------------*/

.case-study-widget {
    padding: 15vh 0;
    background: #EFEFF3;
}

.case-study-widget__header {
    margin-bottom: 7.5vh;
}

.case-study-widget__content {
    margin: 0 -20px;
}

.case-study-widget__item {
    padding: 0 20px;
}

.case-study-widget__link {
    display: block;

    position: relative;
    overflow: hidden;
    border-radius: 28px;
}

.case-study-widget__link:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: #1E1ED2;
        opacity: 0;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
    }

.case-study-widget__link:hover:after, .case-study-widget__link:focus:after {
            opacity: 0.8;
        }

.case-study-widget__link:hover>h2, .case-study-widget__link:focus>h2 {
            opacity: 1;
        }

.case-study-widget__link picture {
    border-radius: 28px;
    overflow: hidden;
}

.case-study-widget__link h2 {
    position: absolute;
    z-index: 3;

    left: 30px;
    bottom: 30px;
    right: 30px;
    color: white;

    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

/*------------------------------------*\
    # block.case-study
\*------------------------------------*/

.case-study {
    position: relative;
    background: #F6F6F7;
}

/* Curtain that uncovers items on scroll */

.case-study .curtain {
    content: '';
    display: block;
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.case-study__item__list {
}

/* Featured item */

.case-study__item {
    padding: 15vh 0 0 0;
    color: #242424;
}

@media    (min-width: 34em) {

.case-study__item {
        padding: 15vh 0
}
    }

.case-study__item--investorsclub {
    background: white;
}

.case-study__item--investorsclub .curtain {
        background: white;
    }

.case-study__item--tolar {
    background: #24273a;
    color: white;
}

.case-study__item--tolar .curtain {
        background: #24273a;
    }

.case-study__item--pwa {
    background: #eaeef3;
}

.case-study__item--pwa .curtain {
        background: #eaeef3;
    }

.case-study__item--happiseek {
    background: #1f1f1f;
    color: white;
}

.case-study__item--happiseek .curtain {
        background: #1f1f1f;
    }

.case-study__item--orqa {
    background: #1e1e22;
    color: white;
}

.case-study__item--orqa .curtain {
        background: #1e1e22;
    }

.case-study__item article {
}

.case-study__item__visual {
    position: relative;
}

@media      (max-width: 33.9375em) {

.case-study__item__visual {
        margin: 0 -40px
}
    }

@media      (max-width: 33.9375em) {

.case-study__item__visual picture {
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden
}
    }

@media    (min-width: 34em) {

.case-study__item__visual picture {
        border-radius: 28px;
        overflow: hidden
}
    }

/* Item headers (hold title and cta */

.case-study__item__header {
    padding-bottom: 10vh;
}

.case-study__item__header__top {
    padding-bottom: 10vh;
}

@media   (min-width: 48em) {

.case-study__item__header__top {
        margin-bottom: 5vh
}
    }

@media   (min-width: 48em) {

.case-study__item__header__top {
        display: flex;
        flex-flow: row wrap
}
    }

.case-study__item__header__numerator {
    padding-top: 8px;
    color: currentColor;
    opacity: 0.4;
}

@media     (max-width: 47.9375em) {

.case-study__item__header__numerator {
        margin-bottom: 2.5vh
}
    }

@media   (min-width: 48em) {

.case-study__item__header__numerator {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/12 - (40px - 40px * 1/12));
        max-width: calc(99.9% * 1/12 - (40px - 40px * 1/12));
        width: calc(99.9% * 1/12 - (40px - 40px * 1/12))
}

.case-study__item__header__numerator:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.case-study__item__header__numerator:last-child {
        margin-right: 0
}

.case-study__item__header__numerator:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (max-width: 47.9375em) {

.case-study__item__header__title {
        margin-bottom: 2.5vh
}
    }

@media   (min-width: 48em) {

.case-study__item__header__title {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 7/12 - (40px - 40px * 7/12));
        max-width: calc(99.9% * 7/12 - (40px - 40px * 7/12));
        width: calc(99.9% * 7/12 - (40px - 40px * 7/12))
}

.case-study__item__header__title:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.case-study__item__header__title:last-child {
        margin-right: 0
}

.case-study__item__header__title:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.case-study__item__header__action {
    text-align: right;
}

@media   (min-width: 48em) {

.case-study__item__header__action {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/12 - (40px - 40px * 1/12));
        max-width: calc(99.9% * 1/12 - (40px - 40px * 1/12));
        width: calc(99.9% * 1/12 - (40px - 40px * 1/12));
        position: relative;
        left: calc(99.9% * 2/12 - (40px - 40px * 2/12) + 40px)
}

.case-study__item__header__action:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.case-study__item__header__action:last-child {
        margin-right: 0
}

.case-study__item__header__action:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.case-study__item__header__meta {
    position: relative;
    padding-top: 2.5vh;
}

.case-study__item__header__meta:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: currentColor;
        opacity: 0.2;
    }

@media    (min-width: 34em) {

.case-study__item__header__meta {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between
}
    }

@media   (min-width: 48em) {

.case-study__item__header__meta {
        margin-left: calc(99.9% * (-1/12 * -1) - (40px - 40px * (-1/12 * -1)) + 40px) !important;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 10/12 - (40px - 40px * 10/12));
        max-width: calc(99.9% * 10/12 - (40px - 40px * 10/12));
        width: calc(99.9% * 10/12 - (40px - 40px * 10/12))
}

.case-study__item__header__meta:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.case-study__item__header__meta:last-child {
        margin-right: 0
}

.case-study__item__header__meta:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media      (max-width: 33.9375em) {
        .case-study__item__header__meta p:first-child {
            margin-bottom: 1.5vh;
        }

        .case-study__item__header__meta p:nth-child(2) {
            opacity: 0.5;
        }
    }

/*------------------------------------*\
    # block.team
\*------------------------------------*/

.team {
    padding: 20vh 0;
    background: #1F1F1F;
    color: white;
}

.team__heading {
    margin-bottom: 15vh;
}

@media   (min-width: 48em) {

.team__heading {
        display: flex;
        flex-flow: row wrap
}
    }

@media   (min-width: 48em) {

.team__heading {
        display: flex;
        flex-flow: row wrap
}
    }

@media     (max-width: 47.9375em) {

.team__heading__content {
        margin-bottom: 10vh
}
    }

@media   (min-width: 48em) {

.team__heading__content {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 7/12 - (40px - 40px * 7/12));
        max-width: calc(99.9% * 7/12 - (40px - 40px * 7/12));
        width: calc(99.9% * 7/12 - (40px - 40px * 7/12))
}

.team__heading__content:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.team__heading__content:last-child {
        margin-right: 0
}

.team__heading__content:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media   (min-width: 48em) {

.team__heading__action {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 2/12 - (40px - 40px * 2/12));
        max-width: calc(99.9% * 2/12 - (40px - 40px * 2/12));
        width: calc(99.9% * 2/12 - (40px - 40px * 2/12));
        margin-left: calc(99.9% * (-2/12 * -1) - (40px - 40px * (-2/12 * -1)) + 40px) !important
}

.team__heading__action:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.team__heading__action:last-child {
        margin-right: 0
}

.team__heading__action:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media    (min-width: 62em) {

.team__heading__action {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 2/12 - (40px - 40px * 2/12));
        max-width: calc(99.9% * 2/12 - (40px - 40px * 2/12));
        width: calc(99.9% * 2/12 - (40px - 40px * 2/12));
        margin-left: calc(99.9% * (-3/12 * -1) - (40px - 40px * (-3/12 * -1)) + 40px) !important
}

.team__heading__action:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.team__heading__action:last-child {
        margin-right: 0
}

.team__heading__action:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.team__list {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 24px;
}

@media   (min-width: 48em) {

.team__list {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 40px
}
    }

@media   (min-width: 75em) {

.team__list {
        grid-template-columns: 1fr 1fr 1fr
}
    }

.team__list__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-bottom: 28px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.team__list__item h3 {
    margin-right: 16px;
}

.team__list__item p {
    text-align: right;
}

/*------------------------------------*\
    # block.dribbble
\*------------------------------------*/

.dribbble {
    background: #1F1F1F;
    color: white;
}

.dribbble__heading {
    padding: 10vh 0;
}

@media   (min-width: 48em) {

.dribbble__heading {
        display: flex;
        flex-flow: row wrap
}
    }

@media   (min-width: 48em) {

.dribbble__heading__content {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 4/12 - (40px - 40px * 4/12));
        max-width: calc(99.9% * 4/12 - (40px - 40px * 4/12));
        width: calc(99.9% * 4/12 - (40px - 40px * 4/12))
}

.dribbble__heading__content:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.dribbble__heading__content:last-child {
        margin-right: 0
}

.dribbble__heading__content:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (max-width: 47.9375em) {

.dribbble__heading__action {
        margin-top: 2.5vh
}
    }

@media   (min-width: 48em) {

.dribbble__heading__action {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 3/12 - (40px - 40px * 3/12));
        max-width: calc(99.9% * 3/12 - (40px - 40px * 3/12));
        width: calc(99.9% * 3/12 - (40px - 40px * 3/12));
        margin-left: calc(99.9% * (-5/12 * -1) - (40px - 40px * (-5/12 * -1)) + 40px) !important;
        text-align: right
}

.dribbble__heading__action:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.dribbble__heading__action:last-child {
        margin-right: 0
}

.dribbble__heading__action:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media      (max-width: 33.9375em) {

.dribbble__slider {
        margin: 0 -40px
}
    }

@media   (min-width: 48em) {
        .dribbble__slider .slick-slide:nth-child(1) {
            padding-right: 20px;
        }

        .dribbble__slider .slick-slide:nth-child(2) {
            padding-left: 20px;
        }
    }

.dribbble__slider__item:focus {
        outline: none;
    }

.dribble__slider__video-player:focus {
        outline: none;
    }

.dribbble__slider img,
.dribbble__slider video {
    width: 100%;
}

@media    (min-width: 34em) {

.dribbble__slider img,
.dribbble__slider video {
        border-radius: 28px
}
    }

/*------------------------------------*\
    # block.cta
\*------------------------------------*/

.cta {
    background: #1F1F1F;
    color: white;
}

.cta__layout {
    padding: 15vh 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

@media   (min-width: 48em) {

.cta .wrapper {
        lost-flex-direction: row
}
    }

.cta__header {
    margin-bottom: 10vh;
}

@media   (min-width: 48em) {

.cta__header {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        max-width: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        width: calc(99.9% * 6/12 - (40px - 40px * 6/12))
}

.cta__header:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.cta__header:last-child {
        margin-right: 0
}

.cta__header:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (max-width: 47.9375em) {
        .cta__content p {
            margin-bottom: 5vh;
        }
    }

@media   (min-width: 48em) {

.cta__content {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 8/12 - (40px - 40px * 8/12));
        max-width: calc(99.9% * 8/12 - (40px - 40px * 8/12));
        width: calc(99.9% * 8/12 - (40px - 40px * 8/12));

        display: -webkit-box;

        display: -ms-flexbox;

        display: flex
}

.cta__content:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.cta__content:last-child {
        margin-right: 0
}

.cta__content:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}

        .cta__content p {
            -webkit-box-flex: 1;
                -ms-flex: 1;
                    flex: 1;
        }

        .cta__content .actioncircle {
            margin-top: -20px;
            margin-left: 40px;
        }
    }

@media    (min-width: 62em) {

.cta__content {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        max-width: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        width: calc(99.9% * 6/12 - (40px - 40px * 6/12))
}

.cta__content:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.cta__content:last-child {
        margin-right: 0
}

.cta__content:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

/*------------------------------------*\
    # block.relay
\*------------------------------------*/

.relay {
    background: #1F1F1F;
    color: white;
}

.relay__layout {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    flex-flow: row wrap;
    padding: 15vh 0;
}

.relay__header {
    margin-bottom: 10vh;
}

.relay__header h1 {
        margin-bottom: 2.5vh;
    }

.relay__header p {
        margin-bottom: 5vh;
    }

@media   (min-width: 48em) {

.relay__header {
        margin-left: calc(99.9% * (-1/12 * -1) - (40px - 40px * (-1/12 * -1)) + 40px) !important;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        max-width: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        width: calc(99.9% * 5/12 - (40px - 40px * 5/12))
}

.relay__header:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.relay__header:last-child {
        margin-right: 0
}

.relay__header:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media   (min-width: 48em) {

.relay__visual {
        margin-left: calc(99.9% * (-1/12 * -1) - (40px - 40px * (-1/12 * -1)) + 40px) !important;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 4/12 - (40px - 40px * 4/12));
        max-width: calc(99.9% * 4/12 - (40px - 40px * 4/12));
        width: calc(99.9% * 4/12 - (40px - 40px * 4/12))
}

.relay__visual:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.relay__visual:last-child {
        margin-right: 0
}

.relay__visual:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

/*------------------------------------*\
    # block.contact-form
\*------------------------------------*/

.contact-form {
    position: relative;
    padding-top: 80px;
    padding-bottom: 60px;
    background: #F6F6F7;
}

@media   (min-width: 48em) {

.contact-form {
        padding-top: 160px;
        padding-bottom: 160px
}
    }

.contact__form__wrapper {
    position: relative;
}

@media   (min-width: 48em) {

.contact__form__wrapper {
        max-width: calc(99.9% * 4/12);
        margin-left: auto;
        margin-right: auto
}

.contact__form__wrapper:before {
        content: '';
        display: table
}

.contact__form__wrapper:after {
        content: '';
        display: table;
        clear: both
}
    }

.contact-form__header {
}

.contact-form__steps {
    position: absolute;
    top: 12px;
    left: -100px;

    width: 50px;
    height: 50px;
    border-radius: 1000px;
}

.contact-form__steps__number {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

    font-family: 'MaisonNeueExtended', Helvetica, Arial, sans-serif;
    font-weight: 300;
    color: #1E1ED2;
}

.contact-form__steps__number--last {
    color: #09bf9e;
}

.contact-form__steps__svg {
}

.contact-form__steps__svg--last path {
    stroke: #09bf9e;
}

.contact-form__paragraph {
    padding-top: 28px;
    padding-bottom: 32px;
}

@media    (min-width: 34em) {

.contact-form__paragraph {
        padding-bottom: 68px
}
    }

.contact-form__form {
}

.contact-form__action {
    margin-top: 16px;
}

.contact-form__action--prev {
        margin-top: 48px;
    }

.contact-form__progress {
    position: absolute;
    top: 0;
    left: 0;
    background: #1E1ED2;
    height: 4px;
    -webkit-transition: all 1s;
    transition: all 1s;
}

.contact-form__progress--1 {
    width: 25%;
}

.contact-form__progress--2 {
    width: 50%;
}

.contact-form__progress--3 {
    width: 75%;
}

.contact-form__progress--4 {
    width: 100%;
    background: #09bf9e;
}

/*------------------------------------*\
    # block.jumpstart
\*------------------------------------*/

.jumpstart {
    position: relative;
    z-index: 0;
    padding: 15vh 0 15vh 0;
    background: #1F1F1F;
    color: #A7A7A7;
}

.jumpstart:after {
        content: 'Careers';
        display: block;
        position: absolute;
        z-index: -1;
        bottom: -20%;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        font-size: 150px;
        color: #1F1F1F;
        -webkit-text-stroke: 1px rgba(255, 255, 255, 0.2);
    }

@media   (min-width: 48em) {

.jumpstart:after {
            font-size: 400px
    }
        }

@media   (min-width: 48em) {

.jumpstart__layout {
        display: flex;
        flex-flow: row wrap;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 8/12 - (40px - 40px * 8/12));
        max-width: calc(99.9% * 8/12 - (40px - 40px * 8/12));
        width: calc(99.9% * 8/12 - (40px - 40px * 8/12))
}

.jumpstart__layout:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.jumpstart__layout:last-child {
        margin-right: 0
}

.jumpstart__layout:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

/*------------------------------------*\
    # block.prototyped
\*------------------------------------*/

.prototyped {
    background: #1F1F1F;
    color: white;
}

.prototyped__layout {
    padding: 15vh 0;
}

@media   (min-width: 48em) {

.prototyped__layout {
        display: flex;
        flex-flow: row wrap;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 10/12 - (40px - 40px * 10/12));
        max-width: calc(99.9% * 10/12 - (40px - 40px * 10/12));
        width: calc(99.9% * 10/12 - (40px - 40px * 10/12));
        margin-left: calc(99.9% * (-1/12 * -1) - (40px - 40px * (-1/12 * -1)) + 40px) !important
}

.prototyped__layout:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.prototyped__layout:last-child {
        margin-right: 0
}

.prototyped__layout:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.prototyped__heading {
    margin-bottom: 10vh;
}

@media   (min-width: 48em) {

.prototyped__heading {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        max-width: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        width: calc(99.9% * 5/12 - (40px - 40px * 5/12))
}

.prototyped__heading:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.prototyped__heading:last-child {
        margin-right: 0
}

.prototyped__heading:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media    (min-width: 34em) {

.prototyped__article__list {
        display: flex;
        flex-flow: row wrap
}
    }

.prototyped__article {
    position: relative;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 20px 0;
}

@media    (min-width: 34em) {

.prototyped__article {
        padding: 40px 0;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        max-width: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        width: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        margin-bottom: 40px
}

.prototyped__article:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.prototyped__article:last-child {
        margin-right: 0
}

.prototyped__article:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}

        .prototyped__article:nth-child(2n) {
            margin-left: calc(99.9% * (-1/12 * -1) - (40px - 40px * (-1/12 * -1)) + 40px) !important;
        }
    }

.prototyped__article__author__link:hover,
    .prototyped__article__author__link:focus {
        color: white;
    }

.prototyped__article__heading {
    margin-bottom: 20px;
}

@media    (min-width: 34em) {

.prototyped__article__heading {
        margin-bottom: 40px;
        min-height: 84px
}
    }

.prototyped__article__title {
    display: block;
}

.prototyped__article__title h1 {
    display: inline;
    color: white;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.prototyped__article__title h1:hover,
    .prototyped__article__title h1:focus {
        border-color: rgba(255, 255, 255, 0.2);
    }

/*------------------------------------*\
    # block.download
\*------------------------------------*/

.download {
    overflow: hidden;
    background: #242424;
    margin-top: -50px;
}

.download__header {
    padding-top: 100px;
}

@media   (min-width: 48em) {

.download__header {
        margin-left: calc(99.9% * (-1/12 * -1) - (40px - 40px * (-1/12 * -1)) + 40px) !important;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        max-width: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        width: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        padding-top: 200px
}

.download__header:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.download__header:last-child {
        margin-right: 0
}

.download__header:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media    (min-width: 62em) {

.download__header {
        margin-left: calc(99.9% * (-2/12 * -1) - (40px - 40px * (-2/12 * -1)) + 40px) !important;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        max-width: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        width: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        padding-top: 280px
}

.download__header:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.download__header:last-child {
        margin-right: 0
}

.download__header:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.download__image {
    position: relative;
    bottom: -200px;
    margin-top: -200px;
}

@media (max-width: 599px) {

.download__image {
        max-width: 400px;
        margin-left: -30px
}
    }

@media (min-width: 600px) {

.download__image {
        margin-left: calc(99.9% * (-7/12 * -1) - (40px - 40px * (-7/12 * -1)) + 40px) !important;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        max-width: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        width: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        margin-top: -300px
}

.download__image:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.download__image:last-child {
        margin-right: 0
}

.download__image:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media   (min-width: 48em) {

.download__image {
        margin-top: -400px
}
    }

.download__title {
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 24px;
}

.download__description {
    font-size: 16px;
    line-height: 28px;
    opacity: 0.7;
    margin-bottom: 52px;
}

.download__button {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    border: 1px solid #5b5b5b;
    height: 80px;
    width: 80px;
    float: left;
}

.download__button:hover,
    .download__button:focus {
        background: rgba(255, 255, 255, 0.1);
    }

.download__button:nth-child(2) {
        margin-left: -1px;
    }

/*------------------------------------*\
    # block.osc
\*------------------------------------*/

.osc {
    padding: 20vh 0;
}

@media   (min-width: 48em) {

.osc__layout {
        display: flex;
        flex-flow: row wrap
}
    }

@media     (max-width: 47.9375em) {

.osc__header {
        margin-bottom: 10vh
}
    }

@media   (min-width: 48em) {

.osc__header {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        max-width: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        width: calc(99.9% * 5/12 - (40px - 40px * 5/12))
}

.osc__header:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.osc__header:last-child {
        margin-right: 0
}

.osc__header:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.osc__header h2 {
    margin-bottom: 10vh;
}

@media   (min-width: 48em) {

.osc__content {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        max-width: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        width: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        margin-left: calc(99.9% * (-1/12 * -1) - (40px - 40px * (-1/12 * -1)) + 40px) !important
}

.osc__content:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.osc__content:last-child {
        margin-right: 0
}

.osc__content:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.osc__list {}

.osc__list__item {
    padding-bottom: 28px;
    margin-bottom: 32px;
    border-bottom: 1px solid #4E4E4E;
}

.osc__list__item:last-child {
        border-bottom: 0;
    }

@media   (min-width: 48em) {

.osc__list__item {
        display: flex;
        flex-flow: row wrap
}
    }

.osc__list__item span {
    display: block;
}

@media   (min-width: 48em) {

.osc__list__item span:nth-child(1) {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 7/12 - (40px - 40px * 7/12));
        max-width: calc(99.9% * 7/12 - (40px - 40px * 7/12));
        width: calc(99.9% * 7/12 - (40px - 40px * 7/12))
}

.osc__list__item span:nth-child(1):nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.osc__list__item span:nth-child(1):last-child {
        margin-right: 0
}

.osc__list__item span:nth-child(1):nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media   (min-width: 48em) {

.osc__list__item span:nth-child(2) {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        max-width: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        width: calc(99.9% * 5/12 - (40px - 40px * 5/12))
}

.osc__list__item span:nth-child(2):nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.osc__list__item span:nth-child(2):last-child {
        margin-right: 0
}

.osc__list__item span:nth-child(2):nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

/*------------------------------------*\
    # block.companyinfo
\*------------------------------------*/

.companyinfo {
    background: #1F1F1F;
    color: white;
}

.companyinfo__wrapper {
    padding: 15vh 0 5vh 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.companyinfo__header {}

.companyinfo__header h1 {
    margin-bottom: 10vh;
}

@media    (min-width: 34em) {

.companyinfo__information {
        display: flex;
        flex-flow: row wrap
}
    }

@media   (min-width: 75em) {

.companyinfo__information {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 8/12 - (40px - 40px * 8/12));
        max-width: calc(99.9% * 8/12 - (40px - 40px * 8/12));
        width: calc(99.9% * 8/12 - (40px - 40px * 8/12))
}

.companyinfo__information:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.companyinfo__information:last-child {
        margin-right: 0
}

.companyinfo__information:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.companyinfo__card {
    padding-bottom: 10vh;
    color: rgba(255, 255, 255, 0.7);
}

@media    (min-width: 34em) {

.companyinfo__card {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        max-width: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        width: calc(99.9% * 6/12 - (40px - 40px * 6/12))
}

.companyinfo__card:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.companyinfo__card:last-child {
        margin-right: 0
}

.companyinfo__card:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media   (min-width: 48em) {

.companyinfo__card {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 4/12 - (40px - 40px * 4/12));
        max-width: calc(99.9% * 4/12 - (40px - 40px * 4/12));
        width: calc(99.9% * 4/12 - (40px - 40px * 4/12))
}

.companyinfo__card:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.companyinfo__card:last-child {
        margin-right: 0
}

.companyinfo__card:nth-child(3n) {
        margin-right: 0;
        margin-left: auto
}
    }

.companyinfo__card h2 {
    margin-bottom: 2.5vh;
}

/**
* Import: views
* Description: specific website/app views (example: 404 view, login view)
*/

/*------------------------------------*\
    # view.project
\*------------------------------------*/

.project {
    background: white;
}

/**
 * Project header
 */

.project__header {
    padding: 20vh 0 15vh 0;
}

@media   (min-width: 48em) {

.project__header {
        display: flex;
        flex-flow: row wrap
}
    }

@media     (max-width: 74.9375em) {

.project__header__left {
        margin-bottom: 5vh
}
    }

@media   (min-width: 48em) {

.project__header__left {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 8/12 - (40px - 40px * 8/12));
        max-width: calc(99.9% * 8/12 - (40px - 40px * 8/12));
        width: calc(99.9% * 8/12 - (40px - 40px * 8/12))
}

.project__header__left:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.project__header__left:last-child {
        margin-right: 0
}

.project__header__left:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.project__header__left h1 {
    margin-bottom: 10vh;
}

@media   (min-width: 75em) {

.project__header__right {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 3/12 - (40px - 40px * 3/12));
        max-width: calc(99.9% * 3/12 - (40px - 40px * 3/12));
        width: calc(99.9% * 3/12 - (40px - 40px * 3/12));
        margin-left: calc(99.9% * (-1/12 * -1) - (40px - 40px * (-1/12 * -1)) + 40px) !important
}

.project__header__right:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.project__header__right:last-child {
        margin-right: 0
}

.project__header__right:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

/**
 * Project intro
 */

.project__intro {
    padding: 15vh 0;
}

@media   (min-width: 48em) {

.project__intro {
        display: flex;
        flex-flow: row wrap
}
    }

@media     (max-width: 47.9375em) {

.project__intro__heading {
        margin-bottom: 10vh
}
    }

@media   (min-width: 48em) {

.project__intro__heading {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 7/12 - (40px - 40px * 7/12));
        max-width: calc(99.9% * 7/12 - (40px - 40px * 7/12));
        width: calc(99.9% * 7/12 - (40px - 40px * 7/12))
}

.project__intro__heading:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.project__intro__heading:last-child {
        margin-right: 0
}

.project__intro__heading:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media   (min-width: 48em) {

.project__intro__content {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 4/12 - (40px - 40px * 4/12));
        max-width: calc(99.9% * 4/12 - (40px - 40px * 4/12));
        width: calc(99.9% * 4/12 - (40px - 40px * 4/12));
        margin-left: calc(99.9% * (-1/12 * -1) - (40px - 40px * (-1/12 * -1)) + 40px) !important
}

.project__intro__content:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.project__intro__content:last-child {
        margin-right: 0
}

.project__intro__content:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

/**
 * Project sections
 */

.project__section {
    padding: 15vh 0;
}

.project__section__heading {
    margin-bottom: 10vh;
}

/**
 * Project sections layout 1
 */

@media   (min-width: 48em) {

.project__section__layout1 {
        display: flex;
        flex-flow: row wrap
}
    }

@media     (max-width: 47.9375em) {

.project__section__layout1__left {
        margin-bottom: 10vh
}
    }

@media   (min-width: 48em) {

.project__section__layout1__left {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        max-width: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        width: calc(99.9% * 6/12 - (40px - 40px * 6/12))
}

.project__section__layout1__left:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.project__section__layout1__left:last-child {
        margin-right: 0
}

.project__section__layout1__left:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media   (min-width: 48em) {

.project__section__layout1__right {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        max-width: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        width: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        margin-left: calc(99.9% * (-1/12 * -1) - (40px - 40px * (-1/12 * -1)) + 40px) !important
}

.project__section__layout1__right:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.project__section__layout1__right:last-child {
        margin-right: 0
}

.project__section__layout1__right:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

/**
 * Project sections layout 2
 */

@media   (min-width: 48em) {

.project__section__layout2 {
        display: flex;
        flex-flow: row wrap
}
    }

@media     (max-width: 47.9375em) {

.project__section__layout2__left {
        margin-bottom: 10vh
}
    }

@media   (min-width: 48em) {

.project__section__layout2__left {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        max-width: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        width: calc(99.9% * 5/12 - (40px - 40px * 5/12))
}

.project__section__layout2__left:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.project__section__layout2__left:last-child {
        margin-right: 0
}

.project__section__layout2__left:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media   (min-width: 48em) {

.project__section__layout2__right {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        max-width: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        width: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        margin-left: calc(99.9% * (-1/12 * -1) - (40px - 40px * (-1/12 * -1)) + 40px) !important
}

.project__section__layout2__right:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.project__section__layout2__right:last-child {
        margin-right: 0
}

.project__section__layout2__right:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

/**
 * Project sections layout 3
 */

.project__section__layout3 {
    padding-top: 15vh;
    background: #313135;
}

.project__section__layout3 .wrapper {
    background: #313135;
}

@media   (min-width: 48em) {

.project__section__layout3 .wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
}
    }

.project__section__layout3 .wrapper:first-of-type {
    margin-bottom: 5vh;
    background: #313135;
}

@media   (min-width: 48em) {

.project__section__layout3 .wrapper:first-of-type {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
}
    }

.project__section__layout3__left {
    background: #313135;
}

@media   (min-width: 48em) {

.project__section__layout3__left {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 8/12 - (40px - 40px * 8/12));
        max-width: calc(99.9% * 8/12 - (40px - 40px * 8/12));
        width: calc(99.9% * 8/12 - (40px - 40px * 8/12))
}

.project__section__layout3__left:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.project__section__layout3__left:last-child {
        margin-right: 0
}

.project__section__layout3__left:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.project__section__layout3__left p {
    margin-bottom: 5vh;
}

@media     (max-width: 47.9375em) {

.project__section__layout3__left h1 {
        margin-bottom: 5vh
}
    }

@media   (min-width: 48em) {

.project__section__layout3__right {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/12 - (40px - 40px * 1/12));
        max-width: calc(99.9% * 1/12 - (40px - 40px * 1/12));
        width: calc(99.9% * 1/12 - (40px - 40px * 1/12));
        position: relative;
        left: calc(99.9% * 2/12 - (40px - 40px * 2/12) + 40px)
}

.project__section__layout3__right:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.project__section__layout3__right:last-child {
        margin-right: 0
}

.project__section__layout3__right:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media   (min-width: 75em) {

.project__section__layout3__right {
        position: relative;
        left: calc(99.9% * 3/12 - (40px - 40px * 3/12) + 40px)
}
    }

/**
 * Project checklist
 */

@media   (min-width: 48em) {

.project__checklist {
        display: flex;
        flex-flow: row wrap
}
    }

.project__checklist li {
    padding-top: 10vh;
    padding-bottom: 24px;
    border-bottom: 1px solid #1F1F1F;
}

@media   (min-width: 48em) {

.project__checklist li {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        max-width: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        width: calc(99.9% * 6/12 - (40px - 40px * 6/12))
}

.project__checklist li:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.project__checklist li:last-child {
        margin-right: 0
}

.project__checklist li:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media    (min-width: 62em) {

.project__checklist li {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 3/12 - (40px - 40px * 3/12));
        max-width: calc(99.9% * 3/12 - (40px - 40px * 3/12));
        width: calc(99.9% * 3/12 - (40px - 40px * 3/12));
        margin-left: calc(99.9% * (-1/12 * -1) - (40px - 40px * (-1/12 * -1)) + 40px) !important
}

.project__checklist li:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.project__checklist li:last-child {
        margin-right: 0
}

.project__checklist li:nth-child(3n) {
        margin-right: 0;
        margin-left: auto
}

        .project__checklist li:first-child,
        .project__checklist li:nth-child(4),
        .project__checklist li:nth-child(8) {
            margin-left: 0 !important;
            margin-right: 40px !important;
        }
    }

.project__checklist__checkmark {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 52px;
    height: 52px;
    margin-bottom: 12px;
    border: 1px solid #1F1F1F;
    border-radius: 50%;
}

/**
 * Project keypoints
 */

@media   (min-width: 48em) {

.project__keypoints {
        display: flex;
        flex-flow: row wrap
}
    }

@media     (max-width: 47.9375em) {

.project__keypoints li {
        margin-bottom: 10vh
}
    }

@media   (min-width: 48em) {

.project__keypoints li {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 2/12 - (40px - 40px * 2/12));
        max-width: calc(99.9% * 2/12 - (40px - 40px * 2/12));
        width: calc(99.9% * 2/12 - (40px - 40px * 2/12))
}

.project__keypoints li:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.project__keypoints li:last-child {
        margin-right: 0
}

.project__keypoints li:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}

        .project__keypoints li:nth-child(2),
        .project__keypoints li:nth-child(3) {
            margin-left: calc(99.9% * (-1/12 * -1) - (40px - 40px * (-1/12 * -1)) + 40px) !important;
        }
    }

.project__keypoints li span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    border: 1px solid #1F1F1F;
    height: 120px;
    width: 120px;
    border-radius: 50%;
    margin: 0 40px 40px 0;
}

@media   (min-width: 48em) {

.project__keypoints li span {
        height: 200px;
        width: 200px
}
    }

.project__keypoints li p {
}

/**
 * Project quote
 */

.project__quote {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-top: 25vh;
}

@media     (max-width: 47.9375em) {

.project__quote {
        padding-top: 5vh
}
    }

.project__quote__icon {
    min-width: 80px;
    padding-right: 40px;
    margin-top: -28px;
}

@media     (max-width: 47.9375em) {

.project__quote__icon {
        min-width: 48px;
        padding-right: 20px
}
    }

.project__quote blockquote {
}

.project__quote cite {
    display: block;
    margin-top: 40px;
}

/**
 * Project meta
 */

.project__meta {
    padding: 15vh 0 20vh 0;
}

@media   (min-width: 48em) {

.project__meta__layout {
        display: flex;
        flex-flow: row wrap
}
    }

@media     (max-width: 47.9375em) {

.project__meta__layout > div {
        margin-bottom: 10vh
}
    }

@media   (min-width: 48em) {

.project__meta__layout > div {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 3/12 - (40px - 40px * 3/12));
        max-width: calc(99.9% * 3/12 - (40px - 40px * 3/12));
        width: calc(99.9% * 3/12 - (40px - 40px * 3/12))
}

.project__meta__layout > div:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.project__meta__layout > div:last-child {
        margin-right: 0
}

.project__meta__layout > div:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.project__meta h2 {
    margin-bottom: 5vh;
}

.project__meta ul {
}

.project__meta ul li {
    padding: 2.5vh 0;
    border-bottom: 1px solid #1F1F1F;
}

/**
 * Project visuals
 */

.project__visual img {
        width: 100%;
    }

.project__visual--fullwidth {
}

/*------------------------------------*\
    # view.page
\*------------------------------------*/

.page {
    padding-bottom: 15vh;
}

@media   (min-width: 48em) {

.page {
        display: flex;
        flex-flow: row wrap
}
    }

@media   (min-width: 48em) {

.page__layout {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 10/12 - (40px - 40px * 10/12));
        max-width: calc(99.9% * 10/12 - (40px - 40px * 10/12));
        width: calc(99.9% * 10/12 - (40px - 40px * 10/12));
        margin-left: calc(99.9% * (-1/12 * -1) - (40px - 40px * (-1/12 * -1)) + 40px) !important
}

.page__layout:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.page__layout:last-child {
        margin-right: 0
}

.page__layout:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media    (min-width: 62em) {

.page__layout {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 8/12 - (40px - 40px * 8/12));
        max-width: calc(99.9% * 8/12 - (40px - 40px * 8/12));
        width: calc(99.9% * 8/12 - (40px - 40px * 8/12));
        margin-left: calc(99.9% * (-2/12 * -1) - (40px - 40px * (-2/12 * -1)) + 40px) !important
}

.page__layout:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.page__layout:last-child {
        margin-right: 0
}

.page__layout:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.page__heading {
    padding: 25vh 0 15vh 0;
    max-width: 660px;
}

.page__intro {
    margin-bottom: 10vh;
}

.page__content {}

.page__section {
    border-top: 1px solid #1F1F1F;
    padding-top: 5vh;
    margin-bottom: 10vh;
}

.page__section:last-child {
        margin-bottom: 0;
    }

@media   (min-width: 48em) {

.page__section {
        display: flex;
        flex-flow: row wrap
}
    }

@media     (max-width: 47.9375em) {

.page__section__heading {
        margin-bottom: 2.5vh
}
    }

@media   (min-width: 48em) {

.page__section__heading {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 3/8 - (40px - 40px * 3/8));
        max-width: calc(99.9% * 3/8 - (40px - 40px * 3/8));
        width: calc(99.9% * 3/8 - (40px - 40px * 3/8))
}

.page__section__heading:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.page__section__heading:last-child {
        margin-right: 0
}

.page__section__heading:nth-child(8n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media   (min-width: 48em) {

.page__section__content {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 4/8 - (40px - 40px * 4/8));
        max-width: calc(99.9% * 4/8 - (40px - 40px * 4/8));
        width: calc(99.9% * 4/8 - (40px - 40px * 4/8));
        margin-left: calc(99.9% * (-1/8 * -1) - (40px - 40px * (-1/8 * -1)) + 40px) !important
}

.page__section__content:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.page__section__content:last-child {
        margin-right: 0
}

.page__section__content:nth-child(8n) {
        margin-right: 0;
        margin-left: auto
}
    }

/*------------------------------------*\
    # view.company
\*------------------------------------*/

.company {}

.company__header {
    padding-top: 25vh;
}

@media   (min-width: 48em) {

.company__header {
        display: flex;
        flex-flow: row wrap
}
    }

@media   (min-width: 48em) {

.company__header h1 {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 7/12 - (40px - 40px * 7/12));
        max-width: calc(99.9% * 7/12 - (40px - 40px * 7/12));
        width: calc(99.9% * 7/12 - (40px - 40px * 7/12))
}

.company__header h1:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.company__header h1:last-child {
        margin-right: 0
}

.company__header h1:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.company__header__keypoints {
    padding-top: 10vh;
}

@media   (min-width: 48em) {

.company__header__keypoints {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 12/12 - (40px - 40px * 12/12));
        max-width: calc(99.9% * 12/12 - (40px - 40px * 12/12));
        width: calc(99.9% * 12/12 - (40px - 40px * 12/12));
        display: flex;
        flex-flow: row wrap
}

.company__header__keypoints:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.company__header__keypoints:last-child {
        margin-right: 0
}

.company__header__keypoints:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.company__header__keypoints>div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

@media     (max-width: 47.9375em) {

.company__header__keypoints>div {
        margin-bottom: 2.5vh
}
    }

@media   (min-width: 48em) {

.company__header__keypoints>div {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        max-width: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        width: calc(99.9% * 6/12 - (40px - 40px * 6/12))
}

.company__header__keypoints>div:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.company__header__keypoints>div:last-child {
        margin-right: 0
}

.company__header__keypoints>div:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media   (min-width: 75em) {

.company__header__keypoints>div {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 4/12 - (40px - 40px * 4/12));
        max-width: calc(99.9% * 4/12 - (40px - 40px * 4/12));
        width: calc(99.9% * 4/12 - (40px - 40px * 4/12))
}

.company__header__keypoints>div:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.company__header__keypoints>div:last-child {
        margin-right: 0
}

.company__header__keypoints>div:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.company__header__keypoints>div>p {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    padding-left: 40px;
}

.company__header__keypoints>div>span {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 120px;
    height: 120px;
    border: 1px solid #4E4E4E;
    border-radius: 50%;
}

@media    (min-width: 62em) {

.company__header__keypoints>div>span {
        width: 200px;
        height: 200px
}
    }

.company__intro {
    padding: 15vh 0;
}

@media   (min-width: 48em) {

.company__intro {
        display: flex;
        flex-flow: row wrap;
        display: flex
}

.company__intro {
        justify-content: center;
        align-items: center
}
    }

@media   (min-width: 48em) {

.company__intro__header {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        max-width: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        width: calc(99.9% * 5/12 - (40px - 40px * 5/12))
}

.company__intro__header:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.company__intro__header:last-child {
        margin-right: 0
}

.company__intro__header:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.company__intro__header p {


    margin-top: 5vh;
    max-width: 520px;
}

@media     (max-width: 47.9375em) {

.company__intro__header p {
        margin-bottom: 10vh;
}
    }

.company__intro__visual {
    /* Unblur resized image */
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

@media     (max-width: 47.9375em) {
        .company__intro__visual img {
            min-width: 600px;
        }
    }

@media   (min-width: 48em) {

.company__intro__visual {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        max-width: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        width: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        margin-left: calc(99.9% * (-1/12 * -1) - (40px - 40px * (-1/12 * -1)) + 40px) !important
}

.company__intro__visual:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.company__intro__visual:last-child {
        margin-right: 0
}

.company__intro__visual:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.company__trusted {
    padding-top: 20vh;
}

.company__trusted h2 {
    margin-bottom: 5vh;
}

.company__trusted p {
    margin-bottom: 10vh;
}

@media     (max-width: 47.9375em) {

.company__trusted img {
        min-width: 800px
}
    }

.company__features {}

.company__features ul li {
    padding: 10vh 0;
    border-bottom: 1px solid #D9D9D9;
}

@media   (min-width: 48em) {

.company__features ul li {
        display: flex;
        flex-flow: row wrap
}
    }

@media     (max-width: 47.9375em) {

.company__features ul li>span {
        display: block;
        margin-bottom: 1vh
}
    }

@media   (min-width: 48em) {

.company__features ul li>span {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/12 - (40px - 40px * 1/12));
        max-width: calc(99.9% * 1/12 - (40px - 40px * 1/12));
        width: calc(99.9% * 1/12 - (40px - 40px * 1/12))
}

.company__features ul li>span:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.company__features ul li>span:last-child {
        margin-right: 0
}

.company__features ul li>span:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (max-width: 47.9375em) {

.company__features ul li>h2 {
        margin-bottom: 2.5vh
}
    }

@media   (min-width: 48em) {

.company__features ul li>h2 {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 4/12 - (40px - 40px * 4/12));
        max-width: calc(99.9% * 4/12 - (40px - 40px * 4/12));
        width: calc(99.9% * 4/12 - (40px - 40px * 4/12))
}

.company__features ul li>h2:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.company__features ul li>h2:last-child {
        margin-right: 0
}

.company__features ul li>h2:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media   (min-width: 48em) {

.company__features ul li>p {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 4/12 - (40px - 40px * 4/12));
        max-width: calc(99.9% * 4/12 - (40px - 40px * 4/12));
        width: calc(99.9% * 4/12 - (40px - 40px * 4/12));
        margin-left: calc(99.9% * (-1/12 * -1) - (40px - 40px * (-1/12 * -1)) + 40px) !important
}

.company__features ul li>p:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.company__features ul li>p:last-child {
        margin-right: 0
}

.company__features ul li>p:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.company__clients {
    padding: 15vh 0;
}

@media   (min-width: 48em) {

.company__clients {
        display: flex;
        flex-flow: row wrap
}
    }

@media     (max-width: 47.9375em) {

.company__clients__header {
        margin-bottom: 5vh
}
    }

@media   (min-width: 48em) {

.company__clients__header {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        max-width: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        width: calc(99.9% * 6/12 - (40px - 40px * 6/12))
}

.company__clients__header:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.company__clients__header:last-child {
        margin-right: 0
}

.company__clients__header:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media   (min-width: 48em) {

.company__clients__list {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        max-width: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        width: calc(99.9% * 6/12 - (40px - 40px * 6/12))
}

.company__clients__list:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.company__clients__list:last-child {
        margin-right: 0
}

.company__clients__list:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.company__clients__list ul {
    -webkit-columns: 2;
       -moz-columns: 2;
            columns: 2;
    -webkit-column-gap: 40px;
       -moz-column-gap: 40px;
            column-gap: 40px;
}

.company__clients__list ul li {
    margin-bottom: 16px;
}

/*------------------------------------*\
    # view.contact
\*------------------------------------*/

.contact {}

.contact__header {
    padding-top: 25vh;
    max-width: 1220px;
}

.contact__link {
    position: relative;
    display: inline-block;
    color: #242424;
    -webkit-transition: all 0.5s cubic-bezier(0.76, 0, 0.24, 1);
    transition: all 0.5s cubic-bezier(0.76, 0, 0.24, 1);
}

@media     (max-width: 47.9375em) {

.contact__link {
        color: #1E1ED2
}
    }

.contact__link:after,
    .contact__link:before {
        content: "";
        position: absolute;
        bottom: 0;
        height: 2px;
        background: rgba(31, 31, 31, 0.3);
        -webkit-transition: all 0.5s cubic-bezier(0.76, 0, 0.24, 1);
        transition: all 0.5s cubic-bezier(0.76, 0, 0.24, 1);
    }

.contact__link:after {
        right: 0;
        width: 100%;
    }

.contact__link:before {
        left: 0;
        width: 0;
    }

.contact__link:hover,
    .contact__link:focus {
        color: #1E1ED2;
    }

.contact__link:hover:after, .contact__link:focus:after {
            width: 0;

            background: #1E1ED2;
        }

.contact__link:hover:before, .contact__link:focus:before {
            width: 100%;
            -webkit-transition-delay: 0.15s;
                    transition-delay: 0.15s;
            background: #1E1ED2;
        }

@media     (max-width: 47.9375em) {

.contact__link span {
            display: none
    }
        }

.contact__body {
    padding: 20vh 0;
}

@media   (min-width: 48em) {

.contact__body {
        display: flex;
        flex-flow: row wrap
}
    }

@media     (max-width: 47.9375em) {
        .contact__body p:first-child {
            margin-bottom: 2.5vh;
        }
    }

@media   (min-width: 48em) {

.contact__body p {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 3/12 - (40px - 40px * 3/12));
        max-width: calc(99.9% * 3/12 - (40px - 40px * 3/12));
        width: calc(99.9% * 3/12 - (40px - 40px * 3/12))
}

.contact__body p:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.contact__body p:last-child {
        margin-right: 0
}

.contact__body p:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

/*------------------------------------*\
    # view.orqa
\*------------------------------------*/

.orqa__header {
        z-index: 0;
        padding-top: 140px;
        text-align: center;
        position: relative;
        color: white;
        background: url('~assets/images/case-studies/orqa/case-header-background-orqa.jpg')
            no-repeat #a995ff;
        background-size: cover;
    }

@media   (min-width: 48em) {

.orqa__header {
            padding-top: 240px
    }
        }

.orqa__full-image {
        background: #F6F6F7;
        width: 100vw;
        height: 400px;
    }

@media   (min-width: 48em) {

.orqa__full-image {
            height: 50vw
    }
        }

@media    (min-width: 62em) {

.orqa__full-image {
            height: 40vw
    }
        }

.orqa__full-image__image {
            z-index: 1;
            background: white;
            position: relative;
            height: 100%;
            width: calc(50% + 740px);
        }

.orqa__challenge {
        z-index: 0;
        position: relative;
        background: white;
        padding-top: 60px;
        padding-bottom: 60px;
    }

@media   (min-width: 48em) {

.orqa__challenge {
            padding-top: 140px
    }
        }

.orqa__challenge:before {
            z-index: -1;
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            background: #F6F6F7;
            width: 100%;
            height: 100%;
        }

@media    (min-width: 62em) {

.orqa__challenge:before {
                width: 65%
        }
            }

@media  (min-width: 104em) {

.orqa__challenge:before {
                width: calc(50% + 250px)
        }
            }

.orqa__challenge__header {
            position: relative;
            max-width: 576px;
        }

@media   (min-width: 48em) {

.orqa__challenge__header {
                margin-left: calc(99.9% * (-2/12 * -1) - (40px - 40px * (-2/12 * -1)) + 40px) !important
        }
            }

@media    (min-width: 62em) {

.orqa__challenge__header {
                margin-left: calc(99.9% * (-6/12 * -1) - (40px - 40px * (-6/12 * -1)) + 40px) !important;
                max-width: 476px
        }
            }

.orqa__challenge__description {
            margin-top: 24px;
        }

.orqa__identity {
        display: flex;
        flex-flow: row wrap;
    }

.orqa__identity__header {
            padding-right: 20px;
            padding-top: 60px;
            padding-bottom: 60px;
        }

@media    (min-width: 62em) {

.orqa__identity__header {
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: calc(99.9% * 1/3);
                max-width: calc(99.9% * 1/3);
                width: calc(99.9% * 1/3);
                padding-bottom: 140px;
                padding-right: 48px
        }

.orqa__identity__header:nth-child(1n) {
                margin-right: 0;
                margin-left: 0
        }

.orqa__identity__header:last-child {
                margin-right: 0
        }

.orqa__identity__header:nth-child(3n) {
                margin-right: 0;
                margin-left: auto
        }
            }

.orqa__identity__color-wrapper {

            /* Fix for lost-column 0 gutter not really being 0. */
            margin: 0 !important;
        }

@media    (min-width: 34em) {

.orqa__identity__color-wrapper {
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: calc(99.9% * 2/2);
                max-width: calc(99.9% * 2/2);
                width: calc(99.9% * 2/2);
        }

.orqa__identity__color-wrapper:nth-child(1n) {
                margin-right: 0;
                margin-left: 0;
        }

.orqa__identity__color-wrapper:last-child {
                margin-right: 0;
        }

.orqa__identity__color-wrapper:nth-child(2n) {
                margin-right: 0;
                margin-left: auto;
        }
            }

@media    (min-width: 62em) {

.orqa__identity__color-wrapper {
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: calc(99.9% * 2/3);
                max-width: calc(99.9% * 2/3);
                width: calc(99.9% * 2/3);
        }

.orqa__identity__color-wrapper:nth-child(1n) {
                margin-right: 0;
                margin-left: 0;
        }

.orqa__identity__color-wrapper:last-child {
                margin-right: 0;
        }

.orqa__identity__color-wrapper:nth-child(3n) {
                margin-right: 0;
                margin-left: auto;
        }
            }

.orqa__identity__color {
            width: 50%;
            float: left;
            -webkit-box-shadow: 5px 5px 35px 0 rgba(0, 0, 0, .075);
                    box-shadow: 5px 5px 35px 0 rgba(0, 0, 0, .075);
        }

@media      (max-width: 33.9375em) {

.orqa__identity__color {
                min-height: 180px
        }
            }

@media    (min-width: 34em) {

.orqa__identity__color {
                min-height: 380px
        }
            }

.orqa__identity__color--black {
                background: #0f0f0f;
                color: white;
            }

.orqa__identity__color--red {
                color: white;
                background: #ff2238;
            }

.orqa__identity__color__title {
                position: relative;
                padding-top: 80px;
                padding-left: 88px;
                font-size: 12px;
                line-height: 16px;
                text-transform: uppercase;
                letter-spacing: 0.1em;
            }

.orqa__identity__color__title:before {
                    content: '';
                    position: absolute;
                    bottom: 6px;
                    left: 0;
                    height: 1px;
                    background: rgb(255, 255, 255);
                    width: 60px;
                }

.orqa__logo {
        text-align: center;
        position: relative;
        overflow: hidden;
        margin-bottom: 40px;
    }

@media   (min-width: 48em) {

.orqa__logo {
            margin-bottom: 100px
    }
        }

@media    (min-width: 62em) {

.orqa__logo {
            margin-bottom: 220px
    }
        }

.orqa__logo__image {
            position: relative;
            z-index: 1;
        }

@media     (max-width: 47.9375em) {

.orqa__logo__image {
                max-width: 200px
        }
            }

.orqa__illustrations {
        background: #212121;
        padding: 100px 0;
    }

.orqa__illustrations__title {
        }

.orqa__illustration {
        position: relative;
        text-align: center;
    }

@media     (max-width: 47.9375em) {
            .orqa__illustration:nth-child(2n) {
                display: none;
            }
        }

@media   (min-width: 48em) {

.orqa__illustration {
            width: calc(99.9% * 1/2 - (40px - 40px * 1/2))
    }

.orqa__illustration:nth-child(1n) {
            float: left;
            margin-right: 40px;
            clear: none
    }

.orqa__illustration:last-child {
            margin-right: 0
    }

.orqa__illustration:nth-child(2n) {
            margin-right: 0;
            float: right
    }

.orqa__illustration:nth-child(2n + 1) {
            clear: both
    }
        }

@media   (min-width: 75em) {

.orqa__illustration {
            width: calc(99.9% * 1/3 - (40px - 40px * 1/3))
    }

.orqa__illustration:nth-child(1n) {
            float: left;
            margin-right: 40px;
            clear: none
    }

.orqa__illustration:last-child {
            margin-right: 0
    }

.orqa__illustration:nth-child(3n) {
            margin-right: 0;
            float: right
    }

.orqa__illustration:nth-child(3n + 1) {
            clear: both
    }
        }

.orqa__illustration__photo {
        }

.orqa__illustration__container:before {
            content: '';
            display: table;
        }

.orqa__illustration__container:after {
            content: '';
            display: table;
            clear: both;
        }

.orqa__outcome {
        margin-top: 60px;
    }

@media   (min-width: 48em) {

.orqa__outcome {
            margin-top: 100px
    }
        }

@media    (min-width: 62em) {

.orqa__outcome {
            margin-top: 220px
    }
        }

/* Fix for IE 11 */

.orqa__outcome .wrapper {
            width: 100%;
        }

@media   (min-width: 48em) {

.orqa__outcome {
            display: flex;
            flex-flow: row wrap;
            max-width: calc(99.9% * 10/12);
            margin-left: auto;
            margin-right: auto
    }
        }

.orqa__outcome__image--1 {
                z-index: 0;
                position: relative;
            }

@media    (min-width: 62em) {

.orqa__outcome__image--1 {
                    flex-grow: 0;
                    flex-shrink: 0;
                    flex-basis: calc(99.9% * 5/10 - (40px - 40px * 5/10));
                    max-width: calc(99.9% * 5/10 - (40px - 40px * 5/10));
                    width: calc(99.9% * 5/10 - (40px - 40px * 5/10));
                    position: relative;
                    left: calc(99.9% * 1/10 - (40px - 40px * 1/10) + 40px)
            }

.orqa__outcome__image--1:nth-child(1n) {
                    margin-right: 40px;
                    margin-left: 0
            }

.orqa__outcome__image--1:last-child {
                    margin-right: 0
            }

.orqa__outcome__image--1:nth-child(10n) {
                    margin-right: 0;
                    margin-left: auto
            }
                }

.orqa__outcome__image--1:before {
                    z-index: -1;
                    content: '';
                    position: absolute;
                    top: 70%;
                    right: 70%;
                    -webkit-transform: translateY(-50%);
                            transform: translateY(-50%);
                    width: 80%;
                    height: 50%;
                    background: #F6F6F7;
                }

@media   (min-width: 75em) {

.orqa__outcome__image--1:before {
                        top: 60%
                }
                    }

.orqa__outcome__image--2 {
                padding: 80px 40px;
                margin-top: 80px;
            }

@media    (min-width: 62em) {

.orqa__outcome__image--2 {
                    padding: 200px 40px;
                    margin-top: 200px
            }
                }

.orqa__outcome__image--2 img {
                    margin: 0 auto;
                    display: block;
                }

.orqa__outcome__header {
            z-index: 1;
            position: relative;
            max-width: 476px;
        }

@media      (max-width: 61.9375em) {

.orqa__outcome__header {
                padding-bottom: 24px
        }
            }

@media    (min-width: 62em) {

.orqa__outcome__header {
                margin-top: 160px;
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: calc(99.9% * 4/10 - (40px - 40px * 4/10));
                max-width: calc(99.9% * 4/10 - (40px - 40px * 4/10));
                width: calc(99.9% * 4/10 - (40px - 40px * 4/10))
        }

.orqa__outcome__header:nth-child(1n) {
                margin-right: 40px;
                margin-left: 0
        }

.orqa__outcome__header:last-child {
                margin-right: 0
        }

.orqa__outcome__header:nth-child(10n) {
                margin-right: 0;
                margin-left: auto
        }
            }

.orqa__outcome__description {
            margin-top: 24px;
        }

/*------------------------------------*\
    # view.openings
\*------------------------------------*/

.openings {}

.openings__header {
    padding: 25vh 0 15vh 0;
}

@media   (min-width: 48em) {

.openings__header {
        display: flex;
        flex-flow: row wrap
}
    }

@media     (max-width: 47.9375em) {

.openings__header h1 {
        margin-bottom: 10vh
}
    }

@media   (min-width: 48em) {

.openings__header h1 {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        max-width: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        width: calc(99.9% * 6/12 - (40px - 40px * 6/12))
}

.openings__header h1:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.openings__header h1:last-child {
        margin-right: 0
}

.openings__header h1:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media    (min-width: 62em) {

.openings__header h1 {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 4/12 - (40px - 40px * 4/12));
        max-width: calc(99.9% * 4/12 - (40px - 40px * 4/12));
        width: calc(99.9% * 4/12 - (40px - 40px * 4/12))
}

.openings__header h1:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.openings__header h1:last-child {
        margin-right: 0
}

.openings__header h1:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media   (min-width: 48em) {

.openings__header p {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        max-width: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        width: calc(99.9% * 6/12 - (40px - 40px * 6/12))
}

.openings__header p:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.openings__header p:last-child {
        margin-right: 0
}

.openings__header p:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media    (min-width: 62em) {

.openings__header p {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        max-width: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        width: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        margin-left: calc(99.9% * (-2/12 * -1) - (40px - 40px * (-2/12 * -1)) + 40px) !important
}

.openings__header p:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.openings__header p:last-child {
        margin-right: 0
}

.openings__header p:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.openings__layout {}

.openings__list {
    margin-top: 80px;
}

.openings__list__item {
    position: relative;
    margin-bottom: 7.5vh;
    padding-bottom: 7.5vh;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.openings__list__item:last-child {
        margin-bottom: 0;
    }

@media   (min-width: 48em) {

.openings__list__item {
        display: flex;
        flex-flow: row wrap
}
    }

@media     (max-width: 47.9375em) {

.openings__list__item__title {
        margin-bottom: 40px
}
    }

@media   (min-width: 48em) {

.openings__list__item__title {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 4/12 - (40px - 40px * 4/12));
        max-width: calc(99.9% * 4/12 - (40px - 40px * 4/12));
        width: calc(99.9% * 4/12 - (40px - 40px * 4/12))
}

.openings__list__item__title:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.openings__list__item__title:last-child {
        margin-right: 0
}

.openings__list__item__title:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media   (min-width: 48em) {

.openings__list__item__content {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 8/12 - (40px - 40px * 8/12));
        max-width: calc(99.9% * 8/12 - (40px - 40px * 8/12));
        width: calc(99.9% * 8/12 - (40px - 40px * 8/12))
}

.openings__list__item__content:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.openings__list__item__content:last-child {
        margin-right: 0
}

.openings__list__item__content:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media   (min-width: 48em) {

.openings__list__item__content {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 7/12 - (40px - 40px * 7/12));
        max-width: calc(99.9% * 7/12 - (40px - 40px * 7/12));
        width: calc(99.9% * 7/12 - (40px - 40px * 7/12));
        position: relative;
        left: calc(99.9% * 1/12 - (40px - 40px * 1/12) + 40px)
}

.openings__list__item__content:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.openings__list__item__content:last-child {
        margin-right: 0
}

.openings__list__item__content:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media   (min-width: 75em) {

.openings__list__item__content {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        max-width: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        width: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        position: relative;
        left: calc(99.9% * 2/12 - (40px - 40px * 2/12) + 40px)
}

.openings__list__item__content:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.openings__list__item__content:last-child {
        margin-right: 0
}

.openings__list__item__content:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.openings__starterkit {
    padding: 15vh 0;
}

@media   (min-width: 48em) {

.openings__starterkit .wrapper {
        lost-flex-direction: row
}
    }

.openings__starterkit__header {
    margin-bottom: 10vh;
}

@media   (min-width: 48em) {

.openings__starterkit__header {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        max-width: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        width: calc(99.9% * 6/12 - (40px - 40px * 6/12))
}

.openings__starterkit__header:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.openings__starterkit__header:last-child {
        margin-right: 0
}

.openings__starterkit__header:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (max-width: 47.9375em) {
        .openings__starterkit__content p {
            margin-bottom: 5vh;
        }
    }

@media   (min-width: 48em) {

.openings__starterkit__content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 8/12 - (40px - 40px * 8/12));
        max-width: calc(99.9% * 8/12 - (40px - 40px * 8/12));
        width: calc(99.9% * 8/12 - (40px - 40px * 8/12))
}

.openings__starterkit__content:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.openings__starterkit__content:last-child {
        margin-right: 0
}

.openings__starterkit__content:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}

        .openings__starterkit__content p {
            -webkit-box-flex: 1;
                -ms-flex: 1;
                    flex: 1;
        }

        .openings__starterkit__content .actioncircle {
            margin-top: -20px;
            margin-left: 40px;
        }
    }

@media    (min-width: 62em) {

.openings__starterkit__content {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        max-width: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        width: calc(99.9% * 6/12 - (40px - 40px * 6/12))
}

.openings__starterkit__content:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.openings__starterkit__content:last-child {
        margin-right: 0
}

.openings__starterkit__content:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

/*------------------------------------*\
    # view.opening (single opening page)
\*------------------------------------*/

.opening {
}

.opening__heading {
    padding: 25vh 0 15vh 0;
    color: white;
}

@media   (min-width: 48em) {

.opening__heading .wrapper {
        display: flex;
        flex-flow: row wrap
}
    }

@media     (max-width: 47.9375em) {

.opening__heading h1 {
        margin-bottom: 10vh
}
    }

@media   (min-width: 48em) {

.opening__heading h1 {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        max-width: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        width: calc(99.9% * 6/12 - (40px - 40px * 6/12))
}

.opening__heading h1:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.opening__heading h1:last-child {
        margin-right: 0
}

.opening__heading h1:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media    (min-width: 62em) {

.opening__heading h1 {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 4/12 - (40px - 40px * 4/12));
        max-width: calc(99.9% * 4/12 - (40px - 40px * 4/12));
        width: calc(99.9% * 4/12 - (40px - 40px * 4/12))
}

.opening__heading h1:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.opening__heading h1:last-child {
        margin-right: 0
}

.opening__heading h1:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media   (min-width: 48em) {

.opening__heading p {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        max-width: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        width: calc(99.9% * 6/12 - (40px - 40px * 6/12))
}

.opening__heading p:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.opening__heading p:last-child {
        margin-right: 0
}

.opening__heading p:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media    (min-width: 62em) {

.opening__heading p {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        max-width: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        width: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        margin-left: calc(99.9% * (-1/12 * -1) - (40px - 40px * (-1/12 * -1)) + 40px) !important
}

.opening__heading p:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.opening__heading p:last-child {
        margin-right: 0
}

.opening__heading p:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.opening__expectations {
    color: white;
}

.opening__expectations h2 {
    margin-bottom: 5vh;
}

@media   (min-width: 48em) {

.opening__checklist {
        display: flex;
        flex-flow: row wrap;
        margin-left: calc(99.9% * -1/12 - (40px - 40px * -1/12) + 40px) !important
}
    }

.opening__checklist li {
    padding-bottom: 10vh;
}

@media   (min-width: 48em) {

.opening__checklist li {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 3/12 - (40px - 40px * 3/12));
        max-width: calc(99.9% * 3/12 - (40px - 40px * 3/12));
        width: calc(99.9% * 3/12 - (40px - 40px * 3/12));
        margin-left: calc(99.9% * (-1/12 * -1) - (40px - 40px * (-1/12 * -1)) + 40px) !important
}

.opening__checklist li:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.opening__checklist li:last-child {
        margin-right: 0
}

.opening__checklist li:nth-child(3n) {
        margin-right: 0;
        margin-left: auto
}
    }

.opening__checklist__checkmark {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 52px;
    height: 52px;
    margin-bottom: 12px;
    border: 1px solid white;
    border-radius: 50%;
}

.opening__checklist__checkmark--optional {
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.opening__section {
    padding: 15vh 0;
    background: white;
}

.opening__section--dark {
    background: #1F1F1F;
    color: white;
}

.opening__section__title {
    margin-bottom: 5vh;
}

.opening__list {
}

.opening__list li {
    padding: 5vh 0;
    border-bottom: 1px solid #D9D9D9;
}

.opening__list li:last-child {
        border-bottom: 0;
    }

@media   (min-width: 48em) {

.opening__list li {
        display: flex;
        flex-flow: row wrap
}
    }

.opening__section--dark .opening__list li {
        border-bottom: 1px solid #4E4E4E;
    }

.opening__section--dark .opening__list li:last-child {
            border-bottom: 0;
        }

.opening__list_icon {
    min-height: 20px;
    min-width: 20px;
    margin-right: 3vw;
}

@media     (max-width: 47.9375em) {

.opening__list__number {
        display: block;
        margin-bottom: 1vh
}
    }

@media   (min-width: 48em) {

.opening__list__number {
        padding-top: 8px;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/12 - (40px - 40px * 1/12));
        max-width: calc(99.9% * 1/12 - (40px - 40px * 1/12));
        width: calc(99.9% * 1/12 - (40px - 40px * 1/12))
}

.opening__list__number:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.opening__list__number:last-child {
        margin-right: 0
}

.opening__list__number:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media     (max-width: 47.9375em) {

.opening__list__title {
        margin-bottom: 2.5vh
}
    }

@media   (min-width: 48em) {

.opening__list__title {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 4/12 - (40px - 40px * 4/12));
        max-width: calc(99.9% * 4/12 - (40px - 40px * 4/12));
        width: calc(99.9% * 4/12 - (40px - 40px * 4/12))
}

.opening__list__title:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.opening__list__title:last-child {
        margin-right: 0
}

.opening__list__title:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.opening__list__description--primary {
    padding-left: calc(20px + 3vw);
}

@media   (min-width: 48em) {

.opening__list__description--primary {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 7/12 - (40px - 40px * 7/12));
        max-width: calc(99.9% * 7/12 - (40px - 40px * 7/12));
        width: calc(99.9% * 7/12 - (40px - 40px * 7/12));
        padding-left: 0
}

.opening__list__description--primary:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.opening__list__description--primary:last-child {
        margin-right: 0
}

.opening__list__description--primary:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media    (min-width: 62em) {

.opening__list__description--primary {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        max-width: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        width: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        padding-left: 0
}

.opening__list__description--primary:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.opening__list__description--primary:last-child {
        margin-right: 0
}

.opening__list__description--primary:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media   (min-width: 48em) {

.opening__list__description--secondary {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 7/12 - (40px - 40px * 7/12));
        max-width: calc(99.9% * 7/12 - (40px - 40px * 7/12));
        width: calc(99.9% * 7/12 - (40px - 40px * 7/12))
}

.opening__list__description--secondary:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.opening__list__description--secondary:last-child {
        margin-right: 0
}

.opening__list__description--secondary:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media    (min-width: 62em) {

.opening__list__description--secondary {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        max-width: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        width: calc(99.9% * 5/12 - (40px - 40px * 5/12))
}

.opening__list__description--secondary:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.opening__list__description--secondary:last-child {
        margin-right: 0
}

.opening__list__description--secondary:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.opening__list__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 11/12 - (40px - 40px * 11/12));
    max-width: calc(99.9% * 11/12 - (40px - 40px * 11/12));
    width: calc(99.9% * 11/12 - (40px - 40px * 11/12));
    margin-bottom: 2vh;
}

.opening__list__wrapper:nth-child(1n) {
    margin-right: 40px;
    margin-left: 0;
}

.opening__list__wrapper:last-child {
    margin-right: 0;
}

.opening__list__wrapper:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
}

@media   (min-width: 48em) {

.opening__list__wrapper {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        max-width: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        width: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        margin-bottom: 0
}

.opening__list__wrapper:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.opening__list__wrapper:last-child {
        margin-right: 0
}

.opening__list__wrapper:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media    (min-width: 62em) {

.opening__list__wrapper {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        max-width: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        width: calc(99.9% * 6/12 - (40px - 40px * 6/12))
}

.opening__list__wrapper:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.opening__list__wrapper:last-child {
        margin-right: 0
}

.opening__list__wrapper:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.opening__blockquote {
    display: flex;
    flex-flow: column nowrap;
    margin-top: 10vh;
}

@media   (min-width: 48em) {

.opening__blockquote {
        display: flex;
        flex-flow: row wrap
}
    }

.opening__blockquote__person {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 88px;
    width: 88px;
}

@media     (max-width: 47.9375em) {

.opening__blockquote__person {
        -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
                order: 2;
        margin-top: 5vh;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 10/12 - (40px - 40px * 10/12));
        max-width: calc(99.9% * 10/12 - (40px - 40px * 10/12));
        width: calc(99.9% * 10/12 - (40px - 40px * 10/12))
}

.opening__blockquote__person:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.opening__blockquote__person:last-child {
        margin-right: 0
}

.opening__blockquote__person:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media   (min-width: 48em) {

.opening__blockquote__person {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        max-width: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        width: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        height: 120px;
        width: 120px
}

.opening__blockquote__person:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.opening__blockquote__person:last-child {
        margin-right: 0
}

.opening__blockquote__person:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media    (min-width: 62em) {

.opening__blockquote__person {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        max-width: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        width: calc(99.9% * 5/12 - (40px - 40px * 5/12))
}

.opening__blockquote__person:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.opening__blockquote__person:last-child {
        margin-right: 0
}

.opening__blockquote__person:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media   (min-width: 48em) {

.opening__blockquote__quote {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        max-width: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        width: calc(99.9% * 6/12 - (40px - 40px * 6/12))
}

.opening__blockquote__quote:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.opening__blockquote__quote:last-child {
        margin-right: 0
}

.opening__blockquote__quote:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.opening__blockquote__image {
    height: 88px;
    width: 88px;
    margin-right: 20px;
    border-radius: 50%;
}

@media   (min-width: 48em) {

.opening__blockquote__image {
        height: 120px;
        width: 120px;
        margin-right: 40px
}
    }

.opening__blockquote__icon {
    height: 40px;
    width: 48px;
    margin-bottom: 12px;
}

@media   (min-width: 48em) {

.opening__blockquote__icon {
        height: 48px;
        width: 64px
}
    }

.opening__action {
    margin-top: 10vh;
}

@media   (min-width: 48em) {

.opening__action {
        display: flex;
        flex-flow: row wrap
}
    }

@media      (max-width: 33.9375em) {

.opening__action p {
        font-size: 20px;
        line-height: 24px
}
    }

@media   (min-width: 48em) {

.opening__action p {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 8/12 - (40px - 40px * 8/12));
        max-width: calc(99.9% * 8/12 - (40px - 40px * 8/12));
        width: calc(99.9% * 8/12 - (40px - 40px * 8/12))
}

.opening__action p:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.opening__action p:last-child {
        margin-right: 0
}

.opening__action p:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

/*------------------------------------*\
    # view.services
\*------------------------------------*/

.services {}

.services__header {
    padding-top: 25vh;
    color: white;
}

@media   (min-width: 48em) {

.services__header {
        display: flex;
        flex-flow: row wrap
}
    }

.services__header h1 {
    margin-bottom: 15vh;
}

@media   (min-width: 48em) {

.services__header h1 {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 7/12 - (40px - 40px * 7/12));
        max-width: calc(99.9% * 7/12 - (40px - 40px * 7/12));
        width: calc(99.9% * 7/12 - (40px - 40px * 7/12))
}

.services__header h1:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.services__header h1:last-child {
        margin-right: 0
}

.services__header h1:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.services__process {
    padding-top: 10vh;
    color: white;
}

.services__process__item {
    border-top: 1px solid white;
    margin-bottom: 20vh;
}

.services__process__item__top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 16px;
    margin-bottom: 7.5vh;

    display: flex;

    flex-flow: row wrap;
}

.services__process__item__top span {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 2/12 - (40px - 40px * 2/12));
    max-width: calc(99.9% * 2/12 - (40px - 40px * 2/12));
    width: calc(99.9% * 2/12 - (40px - 40px * 2/12));
}

.services__process__item__top span:nth-child(1n) {
    margin-right: 40px;
    margin-left: 0;
}

.services__process__item__top span:last-child {
    margin-right: 0;
}

.services__process__item__top span:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
}

@media   (min-width: 48em) {

.services__process__item__top span {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/12 - (40px - 40px * 1/12));
        max-width: calc(99.9% * 1/12 - (40px - 40px * 1/12));
        width: calc(99.9% * 1/12 - (40px - 40px * 1/12))
}

.services__process__item__top span:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.services__process__item__top span:last-child {
        margin-right: 0
}

.services__process__item__top span:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.services__process__item__top h2 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 10/12 - (40px - 40px * 10/12));
    max-width: calc(99.9% * 10/12 - (40px - 40px * 10/12));
    width: calc(99.9% * 10/12 - (40px - 40px * 10/12));
}

.services__process__item__top h2:nth-child(1n) {
    margin-right: 40px;
    margin-left: 0;
}

.services__process__item__top h2:last-child {
    margin-right: 0;
}

.services__process__item__top h2:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
}

@media   (min-width: 48em) {

.services__process__item__top h2 {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 11/12 - (40px - 40px * 11/12));
        max-width: calc(99.9% * 11/12 - (40px - 40px * 11/12));
        width: calc(99.9% * 11/12 - (40px - 40px * 11/12))
}

.services__process__item__top h2:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.services__process__item__top h2:last-child {
        margin-right: 0
}

.services__process__item__top h2:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.services__process__item__top span {}

.services__process__list__item__content {}

.services__process__item__content__top {
    margin-bottom: 15vh;
}

@media   (min-width: 48em) {

.services__process__item__content__top {
        display: flex;
        flex-flow: row wrap
}
    }

@media   (min-width: 48em) {

.services__process__item__content__top>p {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        max-width: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        width: calc(99.9% * 6/12 - (40px - 40px * 6/12))
}

.services__process__item__content__top>p:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.services__process__item__content__top>p:last-child {
        margin-right: 0
}

.services__process__item__content__top>p:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media   (min-width: 48em) {

.services__process__item__content__items {
        display: flex;
        flex-flow: row wrap
}
    }

@media   (min-width: 48em) {

.services__process__item__content__items>p {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 2/12 - (40px - 40px * 2/12));
        max-width: calc(99.9% * 2/12 - (40px - 40px * 2/12));
        width: calc(99.9% * 2/12 - (40px - 40px * 2/12))
}

.services__process__item__content__items>p:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.services__process__item__content__items>p:last-child {
        margin-right: 0
}

.services__process__item__content__items>p:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.services__process__item__visual {
    margin-top: 10vh;
}

.services__process__item__visual img {
    border-radius: 28px;
}

@media     (max-width: 47.9375em) {

.services__process__item__visual img {
        min-width: 600px
}
    }

.services__customercare {
    padding: 15vh 0;
    background: white;
}

@media   (min-width: 48em) {

.services__customercare__layout {
        display: flex;
        flex-flow: row wrap;
        display: flex
}

.services__customercare__layout {
        justify-content: inherit;
        align-items: center
}
    }

@media     (max-width: 47.9375em) {

.services__customercare__header {
        margin-bottom: 10vh
}
    }

@media   (min-width: 48em) {

.services__customercare__header {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        max-width: calc(99.9% * 6/12 - (40px - 40px * 6/12));
        width: calc(99.9% * 6/12 - (40px - 40px * 6/12))
}

.services__customercare__header:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.services__customercare__header:last-child {
        margin-right: 0
}

.services__customercare__header:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

.services__customercare__header h2 {
    max-width: 430px;
    margin-bottom: 5vh;
}

.services__customercare__header p {
    margin-bottom: 5vh;
}

.services__customercare__header ol {
    counter-reset: item;
}

.services__customercare__header ol li {
    margin-bottom: 32px;
}

.services__customercare__header ol li:before {
        font-size: 21px;
        content: "0"counter(item) "  ";
        counter-increment: item;
        margin-top: 2px;
        width: 44px;
        color: #717171;
    }

@media     (max-width: 47.9375em) {

.services__customercare__header ol li:before {
            display: block;
            margin-bottom: 1vh
    }
        }

@media   (min-width: 48em) {

.services__customercare__header ol li {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
}
    }

@media   (min-width: 48em) {

.services__customercare__visual {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 4/12 - (40px - 40px * 4/12));
        max-width: calc(99.9% * 4/12 - (40px - 40px * 4/12));
        width: calc(99.9% * 4/12 - (40px - 40px * 4/12));
        margin-left: calc(99.9% * (-1/12 * -1) - (40px - 40px * (-1/12 * -1)) + 40px) !important
}

.services__customercare__visual:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.services__customercare__visual:last-child {
        margin-right: 0
}

.services__customercare__visual:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

/* Discovery section */

.services__discovery {
    padding: 80px 0 40px 0;
    background: #F6F6F7;
}

@media    (min-width: 34em) {

.services__discovery {
        padding: 120px 0
}
    }

@media    (min-width: 34em) {

.services__discovery .wrapper {
        display: flex;
        flex-flow: row wrap
}
    }

.services__discovery__header {
    margin-bottom: 80px;
}

@media    (min-width: 34em) {

.services__discovery__header {
        position: relative;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        max-width: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        width: calc(99.9% * 5/12 - (40px - 40px * 5/12))
}

.services__discovery__header:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.services__discovery__header:last-child {
        margin-right: 0
}

.services__discovery__header:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media   (min-width: 48em) {

.services__discovery__header {
        margin-bottom: 120px
}
    }

/* Services list of three items */

@media   (min-width: 48em) {

.services__list {
        display: flex;
        flex-flow: row wrap
}
    }

@media     (max-width: 47.9375em) {

.services__list__item {
        margin-bottom: 40px
}
    }

@media    (min-width: 34em) {

.services__list__item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 8/12 - (40px - 40px * 8/12));
        max-width: calc(99.9% * 8/12 - (40px - 40px * 8/12));
        width: calc(99.9% * 8/12 - (40px - 40px * 8/12))
}

.services__list__item:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.services__list__item:last-child {
        margin-right: 0
}

.services__list__item:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}

        .services__list__item:nth-child(2),
        .services__list__item:nth-child(3) {
            margin-left: calc(99.9% * (-1/12 * -1) - (40px - 40px * (-1/12 * -1)) + 40px) !important;
        }
    }

@media   (min-width: 48em) {

.services__list__item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 3/12 - (40px - 40px * 3/12));
        max-width: calc(99.9% * 3/12 - (40px - 40px * 3/12));
        width: calc(99.9% * 3/12 - (40px - 40px * 3/12))
}

.services__list__item:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.services__list__item:last-child {
        margin-right: 0
}

.services__list__item:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}

        .services__list__item:nth-child(2),
        .services__list__item:nth-child(3) {
            margin-left: calc(99.9% * (-1/12 * -1) - (40px - 40px * (-1/12 * -1)) + 40px) !important;
        }
    }

@media    (min-width: 62em) {

.services__list__item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 3/12 - (40px - 40px * 3/12));
        max-width: calc(99.9% * 3/12 - (40px - 40px * 3/12));
        width: calc(99.9% * 3/12 - (40px - 40px * 3/12))
}

.services__list__item:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.services__list__item:last-child {
        margin-right: 0
}

.services__list__item:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}

        .services__list__item:nth-child(2),
        .services__list__item:nth-child(3) {
            margin-left: calc(99.9% * (-1/12 * -1) - (40px - 40px * (-1/12 * -1)) + 40px) !important;
        }
    }

/* Design section */

.services__design {
    padding: 40px 0;
    background: #F6F6F7;
}

@media    (min-width: 34em) {

.services__design {
        padding: 120px 0
}
    }

@media   (min-width: 48em) {

.services__design__wrapper {
        display: flex;
        flex-flow: row wrap
}
    }

.services__design__header {
    margin-bottom: 80px;
}

@media    (min-width: 34em) {

.services__design__header {
        position: relative;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        max-width: calc(99.9% * 5/12 - (40px - 40px * 5/12));
        width: calc(99.9% * 5/12 - (40px - 40px * 5/12))
}

.services__design__header:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.services__design__header:last-child {
        margin-right: 0
}

.services__design__header:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media   (min-width: 48em) {

.services__design__header {
        margin-bottom: 120px
}
    }

.services__design__items {}

.services__design__item {}

/* Development section */

.services--dark {
    background: #1F1F1F;
    color: white;
}

.services__techstack {
    color: white;
    padding-top: 15vh;
}

@media   (min-width: 48em) {

.services__techstack__container {
        display: flex;
        flex-flow: row wrap
}
    }

.services__techstack__category {
    margin-bottom: 40px;
}

@media   (min-width: 48em) {

.services__techstack__category {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 4/12 - (40px - 40px * 4/12));
        max-width: calc(99.9% * 4/12 - (40px - 40px * 4/12));
        width: calc(99.9% * 4/12 - (40px - 40px * 4/12))
}

.services__techstack__category:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.services__techstack__category:last-child {
        margin-right: 0
}

.services__techstack__category:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

@media    (min-width: 62em) {

.services__techstack__category {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 3/12 - (40px - 40px * 3/12));
        max-width: calc(99.9% * 3/12 - (40px - 40px * 3/12));
        width: calc(99.9% * 3/12 - (40px - 40px * 3/12))
}

.services__techstack__category:nth-child(1n) {
        margin-right: 40px;
        margin-left: 0
}

.services__techstack__category:last-child {
        margin-right: 0
}

.services__techstack__category:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }

/*------------------------------------*\
    # view.errorpage
\*------------------------------------*/

.errorpage {
    background: white;

    
}

.errorpage__content {
    padding: 160px 0 160px 0;
    text-align: center;
}

@media   (min-width: 48em) {

.errorpage__content {
        padding: 320px 0 160px 0;
        min-height: 80vh
}
    }

@media    (min-width: 62em) {

.errorpage__content {
        max-width: 640px;
        margin: 0 auto
}
    }

.errorpage__title {
    margin-bottom: 40px;
}

/**
* Import: utils
* Description: reusable utilities such as floats, spacers etc.
*/

/*------------------------------------*\
    # utils.width
\*------------------------------------*/

.w {}

.w--full {
    width: 100%;
}

@media  (min-width: 131.25em) {

.w-negativemargo {
        margin-right: -160px;
        margin-left: -160px
}
    }

.w-breakcontainer {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

/*------------------------------------*\
    # utils.group
\*------------------------------------*/

.u-group:after {
        content: "";
        display: block;
        clear: both;
    }

.u-group--flex {
    display: flex;
    flex-flow: row wrap;
}

/*------------------------------------*\
    # utils.spacers
\*------------------------------------*/

.s-top--tny {
            margin-top: 4px;
        }

.s-top--sml {
            margin-top: 8px;
        }

.s-top--med {
            margin-top: 16px;
        }

.s-top--lrg {
            margin-top: 24px;
        }

.s-top--xlrg {
            margin-top: 32px;
        }

.s-top--xxlrg {
            margin-top: 40px;
        }

.s-right--tny {
            margin-right: 4px;
        }

.s-right--sml {
            margin-right: 8px;
        }

.s-right--med {
            margin-right: 16px;
        }

.s-right--lrg {
            margin-right: 24px;
        }

.s-right--xlrg {
            margin-right: 32px;
        }

.s-right--xxlrg {
            margin-right: 40px;
        }

.s-bottom--tny {
            margin-bottom: 4px;
        }

.s-bottom--sml {
            margin-bottom: 8px;
        }

.s-bottom--med {
            margin-bottom: 16px;
        }

.s-bottom--lrg {
            margin-bottom: 24px;
        }

.s-bottom--xlrg {
            margin-bottom: 32px;
        }

.s-bottom--xxlrg {
            margin-bottom: 40px;
        }

.s-left--tny {
            margin-left: 4px;
        }

.s-left--sml {
            margin-left: 8px;
        }

.s-left--med {
            margin-left: 16px;
        }

.s-left--lrg {
            margin-left: 24px;
        }

.s-left--xlrg {
            margin-left: 32px;
        }

.s-left--xxlrg {
            margin-left: 40px;
        }

/*------------------------------------*\
    # utils.type
\*------------------------------------*/

.t {}

.t-bold {
    font-weight: 700;
}

.t-light {
    font-weight: 300;
}

.t-center {
    text-align: center;
}

.t-upper {
    text-transform: uppercase;
    letter-spacing: 0.2em;
}

.t-serif {
    font-family: Georgia, Times, serif;
}

.t-sans {
    font-family: 'Aeonik', Helvetica, Arial, sans-serif;
}

.t-sans-alt {
    font-family: 'MaisonNeueExtended', Helvetica, Arial, sans-serif;
    -webkit-font-feature-settings: "zero"on;
            font-feature-settings: "zero"on;
    font-weight: 300;
}

/* Sizes */

.t-huge {
    font-size: calc(28.09524px + 3.09524vw);

    line-height: calc(38.09524px + 3.09524vw);
}

@media screen and (min-width: 2000px) {

    .t-huge {
        line-height: 100px;
    }
}

@media screen and (max-width: 320px) {

    .t-huge {
        line-height: 48px;
    }
}

@media screen and (min-width: 2000px) {

    .t-huge {
        font-size: 90px;
    }
}

@media screen and (max-width: 320px) {

    .t-huge {
        font-size: 38px;
    }
}

.t-alpha {
    font-size: calc(32.47619px + 1.72619vw);

    line-height: calc(42.66667px + 1.66667vw);
}

@media screen and (min-width: 2000px) {

    .t-alpha {
        line-height: 76px;
    }
}

@media screen and (max-width: 320px) {

    .t-alpha {
        line-height: 48px;
    }
}

@media screen and (min-width: 2000px) {

    .t-alpha {
        font-size: 67px;
    }
}

@media screen and (max-width: 320px) {

    .t-alpha {
        font-size: 38px;
    }
}

.t-beta {
    font-size: calc(23.80952px + 1.30952vw);

    line-height: calc(35.42857px + 1.42857vw);
}

@media screen and (min-width: 2000px) {

    .t-beta {
        line-height: 64px;
    }
}

@media screen and (max-width: 320px) {

    .t-beta {
        line-height: 40px;
    }
}

@media screen and (min-width: 2000px) {

    .t-beta {
        font-size: 50px;
    }
}

@media screen and (max-width: 320px) {

    .t-beta {
        font-size: 28px;
    }
}

.t-gamma {
    font-size: calc(17.7619px + 1.0119vw);

    line-height: calc(24.19048px + 1.19048vw);
}

@media screen and (min-width: 2000px) {

    .t-gamma {
        line-height: 48px;
    }
}

@media screen and (max-width: 320px) {

    .t-gamma {
        line-height: 28px;
    }
}

@media screen and (min-width: 2000px) {

    .t-gamma {
        font-size: 38px;
    }
}

@media screen and (max-width: 320px) {

    .t-gamma {
        font-size: 21px;
    }
}

.t-delta {
    font-size: calc(19.66667px + 0.41667vw);

    line-height: calc(25.71429px + 0.71429vw);
}

@media screen and (min-width: 2000px) {

    .t-delta {
        line-height: 40px;
    }
}

@media screen and (max-width: 320px) {

    .t-delta {
        line-height: 28px;
    }
}

@media screen and (min-width: 2000px) {

    .t-delta {
        font-size: 28px;
    }
}

@media screen and (max-width: 320px) {

    .t-delta {
        font-size: 21px;
    }
}

.t-epsilon {
    font-size: calc(15.04762px + 0.29762vw);

    line-height: calc(23.2381px + 0.2381vw);
}

@media screen and (min-width: 2000px) {

    .t-epsilon {
        line-height: 28px;
    }
}

@media screen and (max-width: 320px) {

    .t-epsilon {
        line-height: 24px;
    }
}

@media screen and (min-width: 2000px) {

    .t-epsilon {
        font-size: 21px;
    }
}

@media screen and (max-width: 320px) {

    .t-epsilon {
        font-size: 16px;
    }
}

.t-zeta {
    font-size: calc(13.61905px + 0.11905vw);

    line-height: calc(19.2381px + 0.2381vw);
}

@media screen and (min-width: 2000px) {

    .t-zeta {
        line-height: 24px;
    }
}

@media screen and (max-width: 320px) {

    .t-zeta {
        line-height: 20px;
    }
}

@media screen and (min-width: 2000px) {

    .t-zeta {
        font-size: 16px;
    }
}

@media screen and (max-width: 320px) {

    .t-zeta {
        font-size: 14px;
    }
}

.t-caption--sm {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: .2em;
}

/* Colors */

.t-primary {
    color: #1E1ED2;
}

.t-secondary {
    color: #555;
}

.t-negative {
    color: white;
}

.t-color-light {
    color: #717171;
}

.t-color-ultralight {
    color: #A7A7A7;
}

/* Opacity */

.t-opaque {}

.t-opaque-50 {
    opacity: 0.5;
}

.t-opaque-60 {
    opacity: 0.6;
}

.t-opaque-70 {
    opacity: 0.7;
}

.t-opaque-80 {
    opacity: 0.8;
}

.t-opaque-90 {
    opacity: 0.9;
}

.t-smcp {
    font-variant-caps: all-small-caps;
    -webkit-font-feature-settings: 'c2sc', 'smcp', "smcp", "c2sc";
            font-feature-settings: 'c2sc', 'smcp', "smcp", "c2sc";
    letter-spacing: 0.1em;
}

.d-inblock {
    display: inline-block;
}

.t-link--text {
    color: #242424;
    text-decoration: underline;
}

.t-link--negative {
    color: white;
    text-decoration: underline;
}

/*------------------------------------*\
    # utils.list
\*------------------------------------*/

.l {
}

.l-dashed {
}

.l-dashed li {
    position: relative;
    margin-bottom: 8px;
}

.l-dashed li:after {
        content: '\2014';
        position: absolute;
        left: -1.5em;
        top: 0;
        color: rgba(36, 36, 36, 0.3);
    }

.l-numbered {
}

.l-numbered__item {
    position: relative;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;

    padding-bottom: 16px;
    margin-bottom: 16px;

    counter-increment: any-name;
}

.l-numbered__item:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;

        width: 100%;
        height: 1px;

        background: currentColor;
        opacity: 0.1;
    }

.l-numbered__item:before {
        content: '0' counter(any-name);
        min-width: 30px;
        font-size: 10px;
        color: currentColor;
        opacity: 0.5;
    }

.l-numbered__item__title {
}

.l-unordered {
}

.l-unordered__item {
    position: relative;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;

    padding-bottom: 16px;
    margin-bottom: 16px;
}

.l-unordered__item:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;

        width: 100%;
        height: 1px;

        background: currentColor;
        opacity: 0.1;
    }

.l-bordered > li {
        position: relative;

        padding-bottom: 16px;
        margin-bottom: 16px;
    }

.l-bordered > li:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;

            width: 100%;
            height: 1px;

            background: currentColor;
            opacity: 0.1;
        }

.l-bordered > li:last-child:after {
                display: none;
            }

/*------------------------------------*\
    # utils.radius
\*------------------------------------*/

.u {
}

.u-radial {
    border-radius: 4px;
}

.u-rounded {
    border-radius: 1000px;
}

/*------------------------------------*\
    # utils.position
\*------------------------------------*/

.u-pos {
}

.u-pos--rel {
    position: relative;
}

.u-pos--absolute {
    position: absolute;
}

/*------------------------------------*\
    # utils.bg
\*------------------------------------*/

.u-bg {
}

.u-bg--primary {
    background: #1E1ED2;
}

.u-bg--dark {
    background: #1F1F1F;
}

.u-bg--white {
    background: white;
}

.u-bg--mild {
    background: #F6F6F7;
}

/*------------------------------------*\
    # utils.visibility
\*------------------------------------*/

.is {
}

.is-hidden {
    display: none;
}

.is-visible {
    display: block;
}

/*------------------------------------*\
    # utils.color
\*------------------------------------*/

.u-color {
}

.u-color--success {
    color: #4CAF50;
}

.u-color--warning {
    color: #F44336;
}

.u-color--note {
    color: #E2BA45;
}

/*------------------------------------*\
    # utils.row
\*------------------------------------*/

.u-row {
    display: flex;
    flex-flow: row wrap;
}

/*------------------------------------*\
    # utils.shadow
\*------------------------------------*/

.u-shadow {
}

.u-shadow--img {
    -webkit-box-shadow: 15px 15px 70px rgba(0, 0, 0, .1);
            box-shadow: 15px 15px 70px rgba(0, 0, 0, .1);
}

/*------------------------------------*\
    # utils.anime
\*------------------------------------*/

.anime {
}

.anime--opacity {
    opacity: 0;
}

/**
* Import: plugins
* Description: 3rd party code, external plugin CSS etc
* Note: when importing code from a 3rd party it should be stripped off
* any vendor prefixes since autoprefixer will use project specific vendor prefixes
*/

/*------------------------------------*\
    # plugins.fontface
\*------------------------------------*/

@font-face {
    font-family: 'Aeonik';
    src: url('~assets/fonts/Aeonik-Bold/Aeonik-Bold.eot?#iefix');
    src: url('~assets/fonts/Aeonik-Bold/Aeonik-Bold.eot?#iefix') format('eot'),
        url('~assets/fonts/Aeonik-Bold/Aeonik-Bold.woff2') format('woff2'),
        url('~assets/fonts/Aeonik-Bold/Aeonik-Bold.woff') format('woff'),
        url('~assets/fonts/Aeonik-Bold/Aeonik-Bold.ttf') format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'Aeonik';
    src: url('~assets/fonts/Aeonik-BoldItalic/Aeonik-BoldItalic.eot?#iefix');
    src: url('~assets/fonts/Aeonik-BoldItalic/Aeonik-BoldItalic.eot?#iefix')
            format('eot'),
        url('~assets/fonts/Aeonik-BoldItalic/Aeonik-BoldItalic.woff2')
            format('woff2'),
        url('~assets/fonts/Aeonik-BoldItalic/Aeonik-BoldItalic.woff')
            format('woff'),
        url('~assets/fonts/Aeonik-BoldItalic/Aeonik-BoldItalic.ttf')
            format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Aeonik';
    src: url('~assets/fonts/Aeonik-RegularItalic/Aeonik-RegularItalic.eot?#iefix');
    src: url('~assets/fonts/Aeonik-RegularItalic/Aeonik-RegularItalic.eot?#iefix')
            format('eot'),
        url('~assets/fonts/Aeonik-RegularItalic/Aeonik-RegularItalic.woff2')
            format('woff2'),
        url('~assets/fonts/Aeonik-RegularItalic/Aeonik-RegularItalic.woff')
            format('woff'),
        url('~assets/fonts/Aeonik-RegularItalic/Aeonik-RegularItalic.ttf')
            format('truetype');
    font-style: italic;
}

@font-face {
    font-family: 'Aeonik';
    src: url('~assets/fonts/Aeonik-Regular/Aeonik-Regular.eot?#iefix');
    src: url('~assets/fonts/Aeonik-Regular/Aeonik-Regular.eot?#iefix')
            format('eot'),
        url('~assets/fonts/Aeonik-Regular/Aeonik-Regular.woff2') format('woff2'),
        url('~assets/fonts/Aeonik-Regular/Aeonik-Regular.woff') format('woff'),
        url('~assets/fonts/Aeonik-Regular/Aeonik-Regular.ttf')
            format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'MaisonNeueExtended';
    src: url('~assets/fonts/MaisonNeueExtended-Light/MaisonNeueExtended-Light.otf')
            format('opentype'),
        url('~assets/fonts/MaisonNeueExtended-Light/MaisonNeueExtended-Light.woff2')
            format('woff2'),
        url('~assets/fonts/MaisonNeueExtended-Light/MaisonNeueExtended-Light.woff')
            format('woff');
    font-weight: 300;
}

/*------------------------------------*\
    # plugins.slick
\*------------------------------------*/

.slick-list,
.slick-slider,
.slick-track {
    position: relative;
    display: block;
}

.slick-loading .slick-slide,
.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slider {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.slick-list:focus {
    outline: 0;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    top: 0;
    left: 0;
}

.slick-track:after,
.slick-track:before {
    display: table;
    content: "";
}

.slick-track:after {
    clear: both;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    cursor: pointer;
}

[dir="rtl"] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

.slick-slide {
    position: relative;
    color: black;
}

.slick-current {
    min-height: 264px;
}

/*------------------------------------*\
    # plugins.headroom
\*------------------------------------*/

@media    (min-width: 62em) {
    .headroom {
    }

    .headroom--unfixed {
        position: relative;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }

    .headroom--scrolled {
        top: 0;
        left: 0;
        width: 100%;
        -webkit-transition: -webkit-transform 300ms ease-out;
        transition: -webkit-transform 300ms ease-out;
        transition: transform 300ms ease-out;
        transition: transform 300ms ease-out, -webkit-transform 300ms ease-out;
        background: white;
        padding: 24px 0;
    }

    .headroom--unpinned {
        top: 0;
        left: 0;
        width: 100%;
        position: fixed;
        -webkit-transform: translateY(-200px);
                transform: translateY(-200px);
    }

    .headroom--pinned {
        top: 0;
        left: 0;
        width: 100%;
        position: fixed;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

/**
* Import: shame
* Description: CSS shame file
* Note: to be avoided, exists only if REALLY necessary or legacy code
*/

/* @import shame.css"; */